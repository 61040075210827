import { useContext, useState } from "react";
import { TimesheetContext } from "../../contexts/TimesheetContext";
import { useStatsForAdmin } from "../../api/AdminApi/useAdminGetStats";
import { useGetEmployees } from "../../api/useGetEmployees";
import {
    Card,
    DropDownFlex,
    H4,
    P,
    SimpleButton,
    Spinner,
    Stat,
    useToasts,
} from "@tag/tag-components-react-v4";
import { Divider } from "../commons/Divider";
import { DownloadFilled, HourglassEmptyFilled } from "@tag/tag-icons";
import { DepartmentChart } from "../Charts/DepartmentChart";
import { HolidaysChart } from "../Charts/HolidaysChart";
import { ErrorCard } from "../commons/ErrorCard";
import { TOAST_CONTAINER_ID } from "../../constants";
import { useExportIncompleteTimesheet } from "../../api/AdminApi/useExportIncompleteTimesheet";
import { dataYears } from "../../utils/dateUtils";

export const StatisticsComponents = () => {
    const { accessToken } = useContext(TimesheetContext);
    const { toast } = useToasts(TOAST_CONTAINER_ID);
    const pageSize = 2000;
    const [newHireYear, setNewHireYear] = useState(new Date().getFullYear());
    const [newLeaveYear, setNewLeaveYear] = useState(new Date().getFullYear());
    const {
        data: statsData,
        isLoading: isStatsLoading,
        error: statsError,
    } = useStatsForAdmin(accessToken);
    const {
        data: employeeData,
        isLoading: isEmployeeLoading,
        error: employeeError,
    } = useGetEmployees(1, pageSize, "", accessToken);

    const { mutate: exportIncompleteTimesheet, isPending: isExportLoading } =
        useExportIncompleteTimesheet();

    const handleExport = () => {
        toast("Your download will start in a few seconds...", { toastType: "information" });
        exportIncompleteTimesheet(
            { token: accessToken },
            {
                onError: (error) => {
                    toast(`Failed to generate report: ${error.message}`, { toastType: "error" });
                },
                onSuccess: () => {
                    toast("Report generated successfully!", { toastType: "success" });
                },
            },
        );
    };

    if (isStatsLoading || isEmployeeLoading) {
        return (
            <div>
                <Card accent="teal" style={{ textAlign: "center", padding: "1%", margin: "1%" }}>
                    <H4> Statistics </H4>
                </Card>
                <Spinner>Loading ...</Spinner>
            </div>
        );
    }
    if (statsError) {
        return (
            <div>
                <Card accent="teal" style={{ textAlign: "center", padding: "1%", margin: "1%" }}>
                    <H4> Statistics </H4>
                </Card>
                <ErrorCard error={statsError} />
            </div>
        );
    } else if (employeeError) {
        return (
            <div>
                <Card accent="teal" style={{ textAlign: "center", padding: "1%", margin: "1%" }}>
                    <H4> Statistics </H4>
                </Card>
                <ErrorCard error={employeeError} />
            </div>
        );
    }

    return (
        <div>
            <Card accent="teal" style={{ textAlign: "center", padding: "1%", margin: "1%" }}>
                <H4> Statistics </H4>
            </Card>
            {/* active employee, hires, leaves */}
            <div
                style={{
                    margin: "1%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                    gap: "16px",
                }}
            >
                <Stat label="Active employees" orientation="horizontal" accent="green">
                    {statsData?.totalEployees}
                </Stat>
                <div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "8px",
                            padding: "1%",
                        }}
                    >
                        <P>Select Year</P>
                        <DropDownFlex
                            data={dataYears()}
                            onChange={(e) => setNewHireYear(parseInt(e.target.value))}
                            value={newHireYear.toString()}
                        />
                    </div>
                    <Stat label={`Hires ${newHireYear}`} accent="blue" orientation="horizontal">
                        {
                            employeeData?.items.filter(
                                (item) => new Date(item.startDate).getFullYear() === newHireYear,
                            ).length
                        }
                    </Stat>
                </div>
                <div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "8px",
                            padding: "1%",
                        }}
                    >
                        <P>Select Year</P>
                        <DropDownFlex
                            data={dataYears()}
                            onChange={(e) => setNewLeaveYear(parseInt(e.target.value))}
                            value={newLeaveYear.toString()}
                        />
                    </div>
                    <Stat
                        label={`Leaves ${newLeaveYear}`}
                        // variant="outlined"
                        accent="purple"
                        orientation="horizontal"
                    >
                        {
                            employeeData?.items.filter(
                                (item) => new Date(item.leaveDate).getFullYear() === newLeaveYear,
                            ).length
                        }
                    </Stat>
                </div>
            </div>
            {/* ft + pr |  home + office */}
            <div
                style={{
                    margin: "1%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                    gap: "16px",
                }}
            >
                <div style={{ display: "flex", gap: "16px", flexDirection: "column" }}>
                    <Stat label="Full Time" orientation="horizontal" variant="outlined">
                        {statsData?.fullTimers}{" "}
                    </Stat>
                    <Stat label="Part Time" orientation="horizontal" variant="outlined">
                        {statsData?.partTimers}
                    </Stat>
                </div>
                <div style={{ display: "flex", gap: "16px", flexDirection: "column" }}>
                    <Stat label="Home Today " orientation="horizontal" variant="outlined">
                        {statsData?.homeOfficeCount}
                    </Stat>
                    <Stat label="Office Today" orientation="horizontal" variant="outlined">
                        {statsData?.officeCount}
                    </Stat>
                </div>
            </div>
            <Divider content={"Incomplete Timesheets"} orientation={"center"} />
            <div style={{ margin: "1%", display: "flex", gap: "16px", alignItems: "center" }}>
                <div style={{ display: "flex", gap: "5px", flexDirection: "column" }}>
                    <Stat label="Last week" orientation="horizontal" accent="purple">
                        {statsData?.badApplesWeek}
                    </Stat>

                    <Stat label="This Month" orientation="horizontal" accent="purple">
                        {statsData?.badApplesMonth}
                    </Stat>
                </div>
                <SimpleButton
                    accent="teal"
                    startIcon={isExportLoading ? <HourglassEmptyFilled /> : <DownloadFilled />}
                    onClick={handleExport}
                    disabled={isExportLoading}
                >
                    Export Incomplete Timesheet
                </SimpleButton>
            </div>
            <Divider content={"Charts"} orientation={"right"} />
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    margin: "1%",
                }}
            >
                <DepartmentChart statistics={statsData} />
                <HolidaysChart statistics={statsData} />
            </div>
        </div>
    );
};
