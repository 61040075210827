import dayjs from "dayjs";
import { baseURL, ERROR_COULDNT_ADDUPDATE_TIMESHEET, SUCCESS_ADDUPDATE_TIMESHEET, TOAST_CONTAINER_ID } from "../../constants";
import { CreateRDTimesheetModel } from "../../models/CreateTimesheetModel";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToasts } from "@tag/tag-components-react-v4";

const addUpdateRDTimesheet = async (rdTimesheet: CreateRDTimesheetModel, token: string): Promise<any> => {
    const response = await fetch(`${baseURL}/api/RD/UpdateRD`, {
        method: "PUT",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        body: JSON.stringify(rdTimesheet),
    });

    if (!response.ok) {
        let errorMessage = "An error occurred while updating the timesheet.";

        try {
            const errorResponse = await response.json();
            errorMessage = errorResponse.message || JSON.stringify(errorResponse);
        } catch (error) {
            console.error("Error parsing JSON response:", error);
        }

        return Promise.reject(new Error(errorMessage)); // ✅ Properly rejecting the error
    }

    return response.status; 
};

export const useAddUpdateRDTimesheet = () => {
    const queryClient = useQueryClient();
    const { toast } = useToasts(TOAST_CONTAINER_ID);

    return useMutation({
        mutationKey: ["addUpdateRDTimesheet"],
        mutationFn: ({ rdTimesheet, token }: { rdTimesheet: CreateRDTimesheetModel; token: string }) =>
            addUpdateRDTimesheet(rdTimesheet, token),

        onSuccess: () => {
            queryClient.invalidateQueries();
            toast(SUCCESS_ADDUPDATE_TIMESHEET, { toastType: "success" });
        },

        onError: (error) => {
            let errorMessage = "An unknown error occurred.";

            if (error instanceof Error) {
                errorMessage = error.message;
            } else if (typeof error === "string") {
                errorMessage = error;
            }

            console.error("Mutation error:", errorMessage);
            toast(errorMessage, { toastType: "error" });
        },
    });
};
