import { useContext, useEffect, useMemo, useState } from "react";
import { getMonday } from "../../utils/dateUtils";
import dayjs from "dayjs";
import { dayStat } from "../../models/UpdateTimesheetAdminModel";
import advancedFormat from "dayjs/plugin/advancedFormat";
import {
    Badge,
    Card,
    H4,
    Label,
    Pager,
    Searchbox,
    SimpleButton,
    Spinner,
    SquareButton,
    Tooltip,
    useToasts,
} from "@tag/tag-components-react-v4";
import { TimesheetNav } from "../commons/TimesheetNav/TimesheetNav";
import { CircleTickFilled, DeleteFilled, HourglassEmptyFilled, UndoFilled } from "@tag/tag-icons";
import { ManagerRDTeamStatsTable } from "./ManagerRDTeamStatsTable";
import { TimesheetContext } from "../../contexts/TimesheetContext";
import { useQueryClient } from "@tanstack/react-query";
import { useDebounce } from "../../hooks/useDebounce";
import { useValidationsStatsForAdmin } from "../../api/AdminApi/useAdminGetStats";
import { ErrorCard } from "../commons/ErrorCard";
import { useApproveAllTimesheet } from "../../api/RD/useApproveAllTimesheet";
import { TOAST_CONTAINER_ID } from "../../constants";

dayjs.extend(advancedFormat);
type ManagerRDTeamStatsProps = {
    chosenDivision: number | undefined;
};
export const ManagerRDTeamStats = (props: ManagerRDTeamStatsProps) => {
    const { chosenDivision } = props;
    const { accessToken } = useContext(TimesheetContext);
    const { toast } = useToasts(TOAST_CONTAINER_ID);

    const queryClient = useQueryClient();
    const [startDate, setStartDate] = useState<Date>(
        getMonday(dayjs().toDate()), // Monday
    );
    const [endDate, setEndDate] = useState<Date>(
        dayjs(startDate).startOf("week").add(5, "day").toDate(), // Friday
    );
    const [dayStats, setDayStats] = useState<dayStat[]>([]);
    const [selectedEmployee, setSelectedEmployee] = useState<number>(-1);
    const [searchCriteria, setSearchCriteria] = useState<string>("");
    const debouncedSearchCriteria = useDebounce(searchCriteria, 250);
    const [page, setPage] = useState<number>(1);
    const pageSize = 10;
    useMemo(() => {
        setPage(1);
    }, [chosenDivision]);

    // TODO:  GET BASED ON THE DIVISION
    const {
        data: validationStatsForManagerRD,
        isLoading: isStatsLoading,
        error: statsError,
    } = useValidationsStatsForAdmin(
        accessToken,
        startDate,
        endDate,
        page,
        pageSize,
        debouncedSearchCriteria,
        chosenDivision !== undefined,
        chosenDivision, // Provide a default value if chosenDivision is undefined
       
    );
    const { mutate: managerApprovesAllTimesheeet, isPending: managerApprovesAllWaiting } =
        useApproveAllTimesheet();

    const handlePrevOrNextWeek = (direction: "prev" | "next") => {
        const offset = direction === "prev" ? -7 : 7;
        setStartDate(dayjs(startDate).add(offset, "day").toDate());
        setEndDate(dayjs(endDate).add(offset, "day").toDate());
    };
    const handleCurrentWeek = () => {
        setStartDate(dayjs().startOf("week").add(1, "day").toDate()); // Monday
        setEndDate(dayjs().startOf("week").add(5, "day").toDate()); // Friday
    };
    const handleSearch = (e: any) => {
        setSearchCriteria(e);
        setPage(1);
    };

    // TODO : FILL WEEK FUNCTIONALITY

    //   const handleFillWeek = () => {
    // 		dayStats.forEach((day) => {
    // 			console.log(day);
    // 			const timesheetDay: UpdateTimesheetAdminModel = {
    // 				id: day.id,
    // 				date: day.date,
    // 				dayBegin: day.dayBegin,
    // 				dayEnd: day.dayEnd,
    // 				workTypeId: day.workTypeId,
    // 			};
    // 			updateManagerTimesheet(
    // 				{ id: day.profileId, day: timesheetDay, token: accessToken },
    // 				{
    // 					onSuccess: () => {
    // 						toast(`Week filled successfully`, { toastType: "success" });
    // 						setDayStats([]);
    // 						setSelectedEmployee(-1);
    // 						queryClient.invalidateQueries();
    // 					},
    // 					onError: (error) => toast(error.message, { toastType: "error" }),
    // 				},
    // 			);
    // 		});
    // 	};

    const handleApproveAll = () => {
        if (chosenDivision !== undefined) {
            managerApprovesAllTimesheeet(
                {
                    divisionId: chosenDivision,
                },
                {
                    onSuccess: () => {
                        toast(`All Timesheets were approved`, { toastType: "success" });
                        queryClient.invalidateQueries();
                    },
                },
            );
        }
    };

    return (
        <div>
            <Card accent="teal" style={{ textAlign: "center", padding: "1%", margin: "1%" }}>
                <H4> Team Stats </H4>
            </Card>
            <div>
                <div style={{ margin: "1%", display: "flex", gap: "16px", alignItems: "center" }}>
                    <TimesheetNav
                        startDate={startDate}
                        endDate={endDate}
                        handlePrevOrNextWeek={handlePrevOrNextWeek}
                        handleCurrentWeek={handleCurrentWeek}
                    />
                    <Searchbox
                        placeholder="Search by name"
                        style={{ width: "300px" }}
                        onSearch={handleSearch}
                        clearButton
                    />
                    <Tooltip
                        tooltipFor={
                            <SquareButton
                                icon={<UndoFilled />}
                                onClick={() => {
                                    setSelectedEmployee(-1);
                                }}
                            />
                        }
                    >
                        Clear selected
                    </Tooltip>
                    <Badge
                        style={{
                            width: "35px",
                            height: "35px",
                            backgroundColor: `${selectedEmployee > -1 ? "teal" : "sgrey"}`,
                        }}
                    >
                        <Label style={{ color: "white" }}>{selectedEmployee > 0 ? 1 : 0}</Label>
                    </Badge>
                    {/* <SimpleButton
                        endIcon={<CircleTickFilled />}
                        // onClick={() => {
                        //     handleFillWeek();
                        // }}
                        accent="teal"
                        // disabled={selectedEmployee === -1}
                        disabled
                    >
                        Fill Week
                    </SimpleButton> */}
                    {/* <SimpleButton
                        endIcon={<DeleteFilled />}
                        accent="destructive"
                        // disabled={isFillWeekLoading || selectedEmployee === -1}
                        disabled
                    >
                        Delete Week
                    </SimpleButton> */}
                    <SimpleButton
                        endIcon={
                            managerApprovesAllWaiting ? (
                                <HourglassEmptyFilled />
                            ) : (
                                <CircleTickFilled />
                            )
                        }
                        accent="green"
                        onClick={handleApproveAll}
                        disabled={managerApprovesAllWaiting || !validationStatsForManagerRD}
                        // disabled
                    >
                        Approve all
                    </SimpleButton>
                </div>
                {(() => {
                    if (isStatsLoading)
                        return <Spinner style={{ margin: "7%" }}>Loading...</Spinner>;
                    if (statsError) return <ErrorCard error={statsError} />;
                    else if (validationStatsForManagerRD)
                        return (
                            <>
                                <ManagerRDTeamStatsTable
                                    startDate={startDate}
                                    endDate={endDate}
                                    stats={validationStatsForManagerRD}
                                    selectedEmployee={selectedEmployee}
                                    setSelectedEmployee={setSelectedEmployee}
                                    setDayStats={setDayStats}
                                />
                                <Pager
                                    style={{ margin: "1%" }}
                                    page={page}
                                    pageCount={Math.ceil(
                                        validationStatsForManagerRD?.totalCount / pageSize,
                                    )}
                                    accent="teal"
                                    showFirstLast
                                    onPageRequest={(e) => setPage(e.page)}
                                />
                            </>
                        );
                    // }
                })()}
            </div>
        </div>
    );
};
