import {
    Badge,
    Card,
    H4,
    Label,
    Pager,
    Searchbox,
    SimpleButton,
    Spinner,
    SquareButton,
    Tooltip,
    useToasts,
} from "@tag/tag-components-react-v4";
import { useContext, useState } from "react";
import { TimesheetNav } from "../commons/TimesheetNav/TimesheetNav";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";

import { TimesheetContext } from "../../contexts/TimesheetContext";
import { CircleTickFilled, DeleteFilled, UndoFilled } from "@tag/tag-icons";
import { getMonday } from "../../utils/dateUtils";
import { useValidationStatsForManager } from "../../api/ManagersApi/useValidationStatsForManager";
import { ErrorCard } from "../commons/ErrorCard";
import { TOAST_CONTAINER_ID } from "../../constants";
import { useQueryClient } from "@tanstack/react-query";
import { dayStat, UpdateTimesheetAdminModel } from "../../models/UpdateTimesheetAdminModel";
import { AdminStatsTable } from "../Admin/AdminStatsTable";
import { ManagerStatsModel } from "../../models/ManagerStatsModel";
import { useLeaderUpdateTimesheet } from "../../api/ManagersApi/useManagerUpdateTimesheet";

dayjs.extend(advancedFormat);

export const LeaderTeamStats = () => {
    const { accessToken } = useContext(TimesheetContext);
    const { toast } = useToasts(TOAST_CONTAINER_ID);
    const queryClient = useQueryClient();

    const [startDate, setStartDate] = useState<Date>(
        getMonday(dayjs().toDate()), // Monday
    );
    const [endDate, setEndDate] = useState<Date>(
        dayjs(startDate).startOf("week").add(5, "day").toDate(), // Friday
    );
    const [dayStats, setDayStats] = useState<dayStat[]>([]);
    const [selectedEmployee, setSelectedEmployee] = useState<number>(-1);
    const [searchCriteria, setSearchCriteria] = useState<string>("");
    const [page, setPage] = useState<number>(1);
    const pageSize = 10;
    const {
        data: validationStatsForManager,
        isLoading: isValidationStatsLoading,
        error: validationStatsError,
    } = useValidationStatsForManager(accessToken, startDate, endDate);
    const { mutate: updateManagerTimesheet } = useLeaderUpdateTimesheet();

    if (isValidationStatsLoading) return <Spinner style={{ margin: "7%" }}>Loading...</Spinner>;
    if (validationStatsError) return <ErrorCard error={validationStatsError} />;

    let filteredValidationStats: ManagerStatsModel[] | undefined =
        validationStatsForManager?.filter(
            (statForManager) =>
                statForManager.employee.fullName
                    .toLowerCase()
                    .indexOf(searchCriteria.toLowerCase()) > -1,
        );
    const handlePrevOrNextWeek = (direction: "prev" | "next") => {
        const offset = direction === "prev" ? -7 : 7;
        setStartDate(dayjs(startDate).add(offset, "day").toDate());
        setEndDate(dayjs(endDate).add(offset, "day").toDate());
    };
    const handleCurrentWeek = () => {
        setStartDate(dayjs().startOf("week").add(1, "day").toDate()); // Monday
        setEndDate(dayjs().startOf("week").add(5, "day").toDate()); // Friday
    };
    const handleSearch = (e: any) => {
        setSearchCriteria(e);
        setPage(1);
    };
    const handleFillWeek = () => {
        dayStats.forEach((day) => {
            const timesheetDay: UpdateTimesheetAdminModel = {
                id: day.id,
                date: day.date,
                dayBegin: day.dayBegin,
                dayEnd: day.dayEnd,
                workTypeId: day.workTypeId,
            };
            updateManagerTimesheet(
                { id: day.profileId, day: timesheetDay, token: accessToken },
                {
                    onSuccess: () => {
                        toast(`Week filled successfully`, { toastType: "success" });
                        setDayStats([]);
                        setSelectedEmployee(-1);
                        queryClient.invalidateQueries();
                    },
                    onError: (error) => toast(error.message, { toastType: "error" }),
                },
            );
        });
    };

    return (
        <div>
            <Card accent="teal" style={{ textAlign: "center", padding: "1%", margin: "1%" }}>
                <H4> Team Stats </H4>
            </Card>
            <div>
                <div style={{ margin: "1%", display: "flex", gap: "16px", alignItems: "center" }}>
                    <TimesheetNav
                        startDate={startDate}
                        endDate={endDate}
                        handlePrevOrNextWeek={handlePrevOrNextWeek}
                        handleCurrentWeek={handleCurrentWeek}
                    />
                    <Searchbox
                        placeholder="Search by name"
                        style={{ width: "300px" }}
                        onSearch={handleSearch}
                        clearButton
                    />
                    <Tooltip
                        tooltipFor={
                            <SquareButton
                                icon={<UndoFilled />}
                                onClick={() => {
                                    setSelectedEmployee(-1);
                                }}
                            />
                        }
                    >
                        Clear selected
                    </Tooltip>
                    <Badge
                        style={{
                            width: "35px",
                            height: "35px",
                            backgroundColor: `${selectedEmployee > -1 ? "teal" : "sgrey"}`,
                        }}
                    >
                        <Label style={{ color: "white" }}>{selectedEmployee > 0 ? 1 : 0}</Label>
                    </Badge>
                    {/* <SimpleButton
                        endIcon={<CircleTickFilled />}
                        onClick={() => {
                            handleFillWeek();
                        }}
                        accent="teal"
                        disabled={selectedEmployee === -1}
                    >
                        Fill Week
                    </SimpleButton>
                    <SimpleButton
                        endIcon={<DeleteFilled />}
                        accent="destructive"
                        // disabled={isFillWeekLoading || selectedEmployee === -1}
                        disabled
                    >
                        Delete Week
                    </SimpleButton> */}
                </div>
                {(() => {
                    if (filteredValidationStats) {
                        return (
                            <>
                                <AdminStatsTable
                                    selectedEmployee={selectedEmployee}
                                    setSelectedEmployee={setSelectedEmployee}
                                    stats={filteredValidationStats.slice(
                                        (page - 1) * pageSize,
                                        page * pageSize,
                                    )}
                                    startDate={startDate}
                                    endDate={endDate}
                                    dayStats={dayStats}
                                    setDayStats={setDayStats}
                                />
                                <Pager
                                    style={{ margin: "1%" }}
                                    page={page}
                                    pageCount={Math.ceil(filteredValidationStats.length / pageSize)}
                                    accent="teal"
                                    showFirstLast
                                    onPageRequest={(e) => setPage(e.page)}
                                />
                            </>
                        );
                    }
                })()}
            </div>
        </div>
    );
};
