import { useContext } from "react";
import { baseURL } from "../constants";
import { ActivityModel } from "../models/ActivityModel";
import { TimesheetContext } from "../contexts/TimesheetContext";
import { useQuery } from "@tanstack/react-query";

export const fetchActivities = async (token: string): Promise<ActivityModel[]> => {
    const response = await fetch(`${baseURL}/api/Activity/Get?skip=0&take=100`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    if (!response.ok) throw new Error("Failed to fetch activities");
    return await response.json();
};

export const useGetActivities = () => {
    const { accessToken: token } = useContext(TimesheetContext);
    return useQuery<ActivityModel[], Error>({
        queryKey: ["activities"],
        queryFn: () => fetchActivities(token),
        enabled: !!token,
    });
};
