import React from "react";
import { TimesheetPage } from "./TimesheetPage";
import { LeaderPage } from "./LeaderPage";
import { LeavesPage } from "./LeavesPage";
import { ProfilePage } from "./ProfilePage";
import { Navigate, Route, Routes } from "react-router-dom";
import { WorkInProgressCard } from "../components/commons/WorkInProgressCard";
import { LogsPage } from "./LogsPage";
import { EmployeesPage } from "./EmployeesPage";
import { TimesheetIOPage } from "./TimesheetIOPage";
import { StatisticsPage } from "./StatisticsPage";
import { RDTimesheetPage } from "./RDTimesheetPage";
import { ManagerRDPage } from "./ManagerRDPage";
import { RDReportPage } from "./RDReportPage";

export const Pages = () => {
    return (
        <Routes>
            <Route path="/timesheet" element={<TimesheetPage />} />
            <Route path="/timeRD" element={<RDTimesheetPage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/leaves" element={<LeavesPage />} />
            <Route path="/leader" element={<LeaderPage />} />
            <Route path="/employees" element={<EmployeesPage />} />
            <Route path="/IOReport" element={<TimesheetIOPage />} />
            <Route path="/managerRD" element={<ManagerRDPage />} />
            <Route path="/RDReport" element={<RDReportPage />} />
            <Route path="/statistics" element={<StatisticsPage />} />
            <Route path="/logs" element={<LogsPage />} />
            <Route path="/" element={<Navigate to="/profile" replace />} />
            <Route path="*" element={<Navigate to="/profile" replace />} />
            <Route path="/workInProgress" element={<WorkInProgressCard />} />
        </Routes>
    );
};
