import { useContext } from "react";
import { baseURL } from "../constants";

import { TimesheetContext } from "../contexts/TimesheetContext";
import { useQuery } from "@tanstack/react-query";
import { DivisionModel, SubdivisionModel } from "../models/DivisionModel";

export const fetchDivisions = async (token: string): Promise<DivisionModel[]> => {
    const response = await fetch(`${baseURL}/api/Division/Get`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    if (!response.ok) throw new Error("Failed to fetch divisions");
    return await response.json();
};

export const useGetDivisions = () => {
    const { accessToken: token } = useContext(TimesheetContext);
    return useQuery<DivisionModel[], Error>({
        queryKey: ["divisions"],
        queryFn: () => fetchDivisions(token),
        enabled: !!token,
    });
};


export const fetchSubdivisions = async (token: string, divisionId: number): Promise<SubdivisionModel[]> => {
	const response = await fetch(`${baseURL}/api/Subdivision/Get?divisionId=${divisionId}`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
	if (!response.ok) throw new Error("Failed to fetch subdivisions");
	return await response.json();
}

export const useGetSubdivisions = (divisionId: number) => {
	const { accessToken: token } = useContext(TimesheetContext);
	return useQuery<SubdivisionModel[], Error>({
		queryKey: ["subdivisions", divisionId],
		queryFn: () => fetchSubdivisions(token, divisionId),
		enabled: !!token && !!divisionId,
	});
}