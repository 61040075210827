import React, { useContext, useEffect, useState } from "react";
import { ManagerRDBadApplesView } from "../components/ManagerRD/ManagerRDBadApplesView";
import { ManagerRDTeamStats } from "../components/ManagerRD/ManagerRDTeamStats";
import { Card, DropDownFlex, P } from "@tag/tag-components-react-v4";
import { useGetDivisions } from "../api/useGetDivisions";
import { TimesheetContext } from "../contexts/TimesheetContext";

export const ManagerRDPage = () => {
    const { profile } = useContext(TimesheetContext);
    const { data: divisionsData } = useGetDivisions();
    const [chosenDivision, setChosenDivision] = useState<number | undefined>(undefined);

    // Update chosenDivision when profile loads
    useEffect(() => {
        if (profile?.divisionId !== undefined) {
            setChosenDivision(profile.divisionId);
        } else if (chosenDivision === null) {
            setChosenDivision(1); // Default only if `profile.divisionId` never loads
        }
    }, [profile?.divisionId]);
   return (
       <div>
           <Card
               style={{
                   margin: "1%",
                   padding: "1%",
                   display: "flex",
                   alignContent: "center",
                   alignItems: "center",
                   gap: "16px",
               }}
           >
               <P style={{ fontSize: "16px", fontWeight: "500" }}>
                   Choose the division you want to manage
               </P>
               <DropDownFlex
                   textField="name"
                   valueField="id"
                   data={
                       divisionsData
                           ? divisionsData.map((d) => ({
                                 id: d.id,
                                 name: `${d.shortName} - ${d.name}`,
                             }))
                           : []
                   }
                   value={chosenDivision}
                   onChange={(e) => setChosenDivision(parseInt(e.target.value))}
                   style={{ width: "40vh" }}
                   defaultValue={chosenDivision}
               />
           </Card>
           <ManagerRDBadApplesView chosenDivision={chosenDivision} />
           <ManagerRDTeamStats chosenDivision={chosenDivision} />
       </div>
   );
};
