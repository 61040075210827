import { Card, H4, Pager, Searchbox, Spinner } from "@tag/tag-components-react-v4";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { TimesheetNav } from "../commons/TimesheetNav/TimesheetNav";
import { useContext, useState } from "react";
import { getMonday } from "../../utils/dateUtils";
import { RDEmployeeStatsTable } from "./RDEmployeeStatsTable";
import { useValidationsStatsForAdmin } from "../../api/AdminApi/useAdminGetStats";
import { useDebounce } from "../../hooks/useDebounce";
import { TimesheetContext } from "../../contexts/TimesheetContext";
import { ErrorCard } from "../commons/ErrorCard";
import { useGetDivisions } from "../../api/useGetDivisions";

dayjs.extend(advancedFormat);

export const RDEmployeeStats = () => {
    const { accessToken } = useContext(TimesheetContext);
    const [startDate, setStartDate] = useState<Date>(
        getMonday(dayjs().toDate()), // Monday
    );
    const [endDate, setEndDate] = useState<Date>(
        dayjs(startDate).startOf("week").add(5, "day").toDate(), // Friday
    );
   
    const [searchCriteria, setSearchCriteria] = useState<string>("");
    const [page, setPage] = useState<number>(1);
    const pageSize = 10;
    const handlePrevOrNextWeek = (direction: "prev" | "next") => {
        const offset = direction === "prev" ? -7 : 7;
        setStartDate(dayjs(startDate).add(offset, "day").toDate());
        setEndDate(dayjs(endDate).add(offset, "day").toDate());
    };
    const handleCurrentWeek = () => {
        setStartDate(dayjs().startOf("week").add(1, "day").toDate()); // Monday
        setEndDate(dayjs().startOf("week").add(5, "day").toDate()); // Friday
    };
    const handleSearch = (e: any) => {
        setSearchCriteria(e);
        setPage(1);
    };
    const debouncedSearchCriteria = useDebounce(searchCriteria, 250);

    const {
        data: validationStatsForManagerRD,
        isLoading: isStatsLoading,
        error: statsError,
    } = useValidationsStatsForAdmin(
        accessToken,
        startDate,
        endDate,
        page,
        pageSize,
        debouncedSearchCriteria,
        true,
    );
    const {data: divisions} = useGetDivisions();
    return (
        <div>
            <Card accent="teal" style={{ textAlign: "center", padding: "1%", margin: "1%" }}>
                <H4> Team Stats </H4>
            </Card>
            <div>
                <div style={{ margin: "1%", display: "flex", gap: "16px", alignItems: "center" }}>
                    <TimesheetNav
                        startDate={startDate}
                        endDate={endDate}
                        handlePrevOrNextWeek={handlePrevOrNextWeek}
                        handleCurrentWeek={handleCurrentWeek}
                    />
                    <Searchbox
                        placeholder="Search by name"
                        style={{ width: "300px" }}
                        onSearch={handleSearch}
                        clearButton
                    />
                </div>
                {(() => {
                    if (isStatsLoading)
                        return <Spinner style={{ margin: "7%" }}>Loading...</Spinner>;
                    if (statsError) return <ErrorCard error={statsError} />;
                    else if (validationStatsForManagerRD)
                        return (
                            <div>
                                <RDEmployeeStatsTable
                                    startDate={startDate}
                                    endDate={endDate}
                                    stats={validationStatsForManagerRD}
                                    divisions={divisions}
                                />
                                <Pager
                                    style={{ margin: "1%" }}
                                    page={page}
                                    pageCount={Math.ceil(
                                        validationStatsForManagerRD?.totalCount / pageSize,
                                    )}
                                    accent="teal"
                                    showFirstLast
                                    onPageRequest={(e) => setPage(e.page)}
                                />
                            </div>
                        );
                    //}
                })()}
            </div>
        </div>
    );
};
