import {
    Alert,
    Checkbox,
    Table,
    TableBody,
    TableBodyCell,
    TableBodyRow,
    TableHead,
    TableHeadCell,
    TableHeadRow,
    Tooltip,
} from "@tag/tag-components-react-v4";
import {
    getActivityNameById,
    getApproverNamesForDays,
    getPendingActivities,
    getStatsColumns,
    isAllDaysApproved,
} from "../../utils/managerUtils";
import { ManagerStatsModel } from "../../models/ManagerStatsModel";
import { TimesheetModel } from "../../models/TimesheetModel";
import { dayStat } from "../../models/UpdateTimesheetAdminModel";
import { getCellStyles } from "../../utils/ioReportUtils";
import { getDifHours } from "../../utils/dateUtils";
import { Circle3QuarterFilled, CircleTickFilled } from "@tag/tag-icons";
import dayjs from "dayjs";
import { useGetActivities } from "../../api/useGetActivites";
import { ActivityModel } from "../../models/ActivityModel";

export type managerRDTeamStatsTableProps = {
    selectedEmployee: number;
    setSelectedEmployee: (e: number) => void;
    startDate: Date;
    endDate: Date;
    stats: any;
    setDayStats: (e: dayStat[] | ((prevDayStats: dayStat[]) => dayStat[])) => void;
};
export const ManagerRDTeamStatsTable = (props: managerRDTeamStatsTableProps) => {
    const { startDate, endDate, stats, selectedEmployee, setSelectedEmployee, setDayStats } = props;
    const { data: activities } = useGetActivities();
    const columns = getStatsColumns(startDate, endDate, false);
    const isStatSelected = (stat: ManagerStatsModel) => {
        return selectedEmployee === stat.employee.id;
    };
    const handleCheckboxChange = (id: number, isChecked: boolean, stat: ManagerStatsModel) => {
        setSelectedEmployee(isChecked ? id : -1);
        if (isChecked) {
            const newDays = stat.days
                .filter((day: TimesheetModel) => !day.holidayType && !day.dayBegin && !day.dayEnd)
                .map((day: TimesheetModel) => ({
                    id: day.id,
                    date: new Date(day.date).toDateString(),
                    dayBegin: stat.employee.scheduleBegin,
                    dayEnd: stat.employee.scheduleEnd,
                    workTypeId: null,
                    profileId: id,
                }));
            setDayStats(newDays);
        } else {
            setDayStats([]);
        }
    };

    // 8- cirlce / 8 tick
    if (stats.items.length === 0) {
        return <Alert style={{ margin: "1%" }}>No data found</Alert>;
    }
    return (
        <div style={{ margin: "1%" }}>
            <div style={{ height: "30rem" }}>
                <Table>
                    <TableHead>
                        <TableHeadRow>
                            {columns.map((column) => (
                                <TableHeadCell
                                    key={column}
                                    style={{
                                        width: "50px",
                                        // width: column === "Selected" ? "25px" : "50px",
                                        textAlign: "center",
                                        borderRight: "1px solid #e0e0e0",
                                    }}
                                >
                                    {column}
                                </TableHeadCell>
                            ))}
                        </TableHeadRow>
                    </TableHead>
                    <TableBody>
                        {stats.items.map((stat: ManagerStatsModel) => (
                            <TableBodyRow key={`key-${stat.employee.marca}-${stat.employee.email}`}>
                                <TableBodyCell
                                    style={{
                                        width: "25px",
                                        textAlign: "center",
                                        borderRight: "1px solid #e0e0e0",
                                    }}
                                >
                                    <Checkbox
                                        checked={isStatSelected(stat)}
                                        onChange={(e) => {
                                            handleCheckboxChange(stat.employee.id, e.checked, stat);
                                        }}
                                    />
                                </TableBodyCell>
                                <TableBodyCell
                                    style={{
                                        width: "50px",
                                        textAlign: "center",
                                        borderRight: "1px solid #e0e0e0",
                                    }}
                                >
                                    {stat.employee.fullName}
                                </TableBodyCell>
                                {stat.days.map((day: TimesheetModel) => {
                                    return (
                                        <TableBodyCell
                                            key={`key-${day.date}`}
                                            style={{
                                                textAlign: "center",
                                                borderRight: "1px solid #e0e0e0",
                                                ...getCellStyles(isStatSelected(stat), day),
                                            }}
                                        >
                                            {CustomCell(day, activities ?? [])}
                                        </TableBodyCell>
                                    );
                                })}
                            </TableBodyRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </div>
    );
};
const CustomCell = (data: TimesheetModel, activities: ActivityModel[]) => {
    // ✅ Ensure hook is always called

    // ✅ Default empty state
    if (data.id === null && data.holidayType === null) {
        return <span>-</span>;
    }

    if (data.holidayType?.shortName) {
        return <span>{data.holidayType.shortName}</span>;
    }

    if (data.dayBegin && data.dayEnd) {
        return (
            <span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                {getDifHours(data.dayBegin, data.dayEnd)}
                {isAllDaysApproved(data) ? (
                    <Tooltip
                        tooltipFor={
                            <CircleTickFilled
                                accent="green"
                                size="small"
                                style={{ width: "24px" }}
                            />
                        }
                    >
                        {getApproverNamesForDays(data).map((approver, index) => (
                            <div key={`${index}-${approver.approvedDate}`}>
                                {`Activity: ${getActivityNameById(
                                    approver.activityId,
                                    activities ?? [],
                                )} - ${approver.hoursPerActivity}h, Approved by: ${
                                    approver.approverName
                                } on ${dayjs(approver.approvedDate).format("DD-MM-YYYY")}`}
                            </div>
                        ))}
                    </Tooltip>
                ) : (
                    <Tooltip
                        tooltipFor={<Circle3QuarterFilled size="small" style={{ width: "20px" }} />}
                    >
                        {getPendingActivities(data).map((activity, index) => (
                            <div key={`${index}-${activity.activityId}`}>
                                {`Pending-Activity: ${getActivityNameById(
                                    activity.activityId,
                                    activities ?? [],
                                )} - ${activity.hoursPerActivity}h`}
                            </div>
                        ))}
                    </Tooltip>
                )}
            </span>
        );
    }

    // return <div>-</div>;
};

