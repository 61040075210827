import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import duration from "dayjs/plugin/duration";
dayjs.extend(LocalizedFormat);
dayjs.extend(duration);
export const ParseDate = (date: Date): string => {
	const Add0IfMissing = (d: number) => (d < 10 ? "0" + d : d);

	return (
		date.getFullYear() +
		"-" +
		Add0IfMissing(date.getMonth() + 1) +
		"-" +
		Add0IfMissing(date.getDate())
	);
};

export const getMonday = (date: Date): Date => {
	return dayjs(date).startOf("week").add(1, "day").toDate();
};

export const shotMonthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];
export const longMonthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export const dataYears = (): string[] => {
    const currentYear = dayjs().year();
    const startYear = 2020;
    const years = [];
    for (let year = startYear; year <= currentYear; year++) {
        years.push(year.toString());
    }
    return years;
};

export const makeTimes = () => {
    return Array.from(Array(57)).map((_v, i) => {
        const hours = 7 + Math.floor(i / 4);
        const minutes = (i % 4) * 15;
        return {
            id: i + 1,
            time: `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`,
        };
    });
};

export const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri"];
export const dataMonths = [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
];

export const isPastMonth = (startDate: Date, endDate: Date) => {
    const startOfCurrentMonth = dayjs().startOf("month");
    const endOfCurrentMonth = dayjs().endOf("month");

    if (dayjs(endDate).isBefore(startOfCurrentMonth)) {
        return true;
    }

    if (dayjs(startDate).isAfter(endOfCurrentMonth)) {
        return false;
    }

    return (
        dayjs(startDate).isBefore(startOfCurrentMonth) &&
        dayjs(endDate).isBefore(startOfCurrentMonth)
    );
};
export const isFutureMonth = (startDate: Date, endDate: Date) => {
    const startOfCurrentMonth = dayjs().startOf("month");
    const endOfCurrentMonth = dayjs().endOf("month");

    if (dayjs(endDate).isBefore(startOfCurrentMonth)) {
        return false;
    }

    if (dayjs(startDate).isAfter(endOfCurrentMonth)) {
        return true;
    }

    return dayjs(startDate).isAfter(endOfCurrentMonth) && dayjs(endDate).isAfter(endOfCurrentMonth);
};

export const isCurrentMonth = (date: string) => {
    const currentMonth = dayjs().month();
    return dayjs(date, "Do MMM YYYY").month() === currentMonth;
};

export const isAfterLast3rdWorkingDay = () => {
    const today = dayjs();
    const lastDayOfMonth = today.endOf("month");
    let workingDaysCount = 0;

    for (
        let day = lastDayOfMonth;
        day.isAfter(today.startOf("month"));
        day = day.subtract(1, "day")
    ) {
        if (day.day() >= 1 && day.day() <= 5) {
            // Include only weekdays (Monday to Friday)
            workingDaysCount++;
            if (workingDaysCount > 3) {
                return today.isAfter(day);
            }
        }
    }

    return false;
};


export const getDifHours = (start: string, end: string) => {
    const startTime = dayjs(start, "HH:mm");
    const endTime = dayjs(end, "HH:mm");
    const duration = dayjs.duration(endTime.diff(startTime));
    const hours = duration.asHours();
    return hours;
}