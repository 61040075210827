import { Card, H4, Pager } from "@tag/tag-components-react-v4";
import { LeaderBadApplesTabs } from "./LeaderBadApplesTabs";
import { useBadApples } from "../../api/useBadApples";
import { useContext, useState } from "react";
import { TimesheetContext } from "../../contexts/TimesheetContext";
import { IncompleteListModel } from "../../models/IncompleteTimesheetsModel";

export const LeaderBadApplesView = () => {
    const { accessToken } = useContext(TimesheetContext);
    const [lastWeekPage, setLastWeekPage] = useState<number>(1);
    const [thisMonthPage, setThisMonthPage] = useState<number>(1);
    const {
        data: badApplesData,
        isLoading: isBadApplesLoading,
        error,
    } = useBadApples(lastWeekPage, thisMonthPage, accessToken);

    return (
        <>
            <Card accent="teal" style={{ textAlign: "center", padding: "1%", margin: "1%" }}>
                <H4> Incomplete Timesheets </H4>
            </Card>

            <div style={{ margin: "1%", display: "flex", gap: "10rem" }}>
                <div>
                    <Card
                        style={{ textAlign: "center", padding: "1%", backgroundColor: "#db7093" }}
                    >
                        <strong>Last Week</strong>
                    </Card>
                    <LeaderBadApplesTabs
                        isLastWeek={true}
                        isLoading={isBadApplesLoading}
                        error={error}
                        page={lastWeekPage}
                        setPage={setLastWeekPage}
                        badApplesData={badApplesData?.lastWeek}
                    />
                </div>
                <div>
                    <Card
                        style={{ textAlign: "center", padding: "1%", backgroundColor: "#db7093" }}
                    >
                        <strong>This Month</strong>
                    </Card>
                    <LeaderBadApplesTabs
                        isLastWeek={false}
                        isLoading={isBadApplesLoading}
                        error={error}
                        badApplesData={badApplesData?.thisMonth}
                        page={thisMonthPage}
                        setPage={setThisMonthPage}
                    />
                </div>
            </div>
        </>
    );
};
