import React, { useContext, useState } from "react";
import { TimesheetContext } from "../contexts/TimesheetContext";
import { getMonday } from "../utils/dateUtils";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { TimesheetNav } from "../components/commons/TimesheetNav/TimesheetNav";
import { RDTable } from "../components/RDTimesheet/RDTable";
import { Alert } from "@tag/tag-components-react-v4";

dayjs.extend(LocalizedFormat);
export const RDTimesheetPage = () => {
    const { accessToken } = useContext(TimesheetContext);
    const [startDate, setStartDate] = useState<Date>(
        getMonday(new Date()), // Monday
    );
    const [endDate, setEndDate] = useState<Date>(
        dayjs(startDate).startOf("week").add(5, "day").toDate(), // Friday
    );
    const handlePrevOrNextWeek = (direction: "prev" | "next") => {
        const offset = direction === "prev" ? -7 : 7;
        setStartDate(dayjs(startDate).add(offset, "day").toDate());
        setEndDate(dayjs(endDate).add(offset, "day").toDate());
    };
    const handleCurrentWeek = () => {
        setStartDate(dayjs().startOf("week").add(1, "day").toDate()); // Monday
        setEndDate(dayjs().startOf("week").add(5, "day").toDate()); // Friday
    };
    return (
        <div style={{ margin: "1%" }}>
            {/* <div style={{ display: "flex", justifyContent: "flex-start", gap: "32px" }}> */}
            <TimesheetNav
                startDate={startDate}
                endDate={endDate}
                handlePrevOrNextWeek={handlePrevOrNextWeek}
                handleCurrentWeek={handleCurrentWeek}
            />
            {/* <Alert closeTransition="fade" type="warning" closeable>
                    You can <b>not</b> save the timesheet <b>after</b>{" "}
                    <ins>the last 3rd work day of the current month! </ins>
                </Alert> */}
            {/* </div> */}
            <RDTable startDate={startDate} endDate={endDate} />
        </div>
    );
};
