import React from "react";
import { getStatsColumns } from "../../utils/managerUtils";
import {
    Checkbox,
    Table,
    TableBody,
    TableBodyCell,
    TableBodyRow,
    TableHead,
    TableHeadCell,
    TableHeadRow,
} from "@tag/tag-components-react-v4";
import { ManagerStatsModel } from "../../models/ManagerStatsModel";
import { TimesheetModel } from "../../models/TimesheetModel";
import { getCellStyles } from "../../utils/ioReportUtils";
import { dayStat } from "../../models/UpdateTimesheetAdminModel";

export type AdminStatsTableProps = {
   
    selectedEmployee: number;
    setSelectedEmployee: (e: number) => void;
    stats: any;
    startDate: Date;
    endDate: Date;
    dayStats: dayStat[];
    setDayStats: (e: dayStat[] | ((prevDayStats: dayStat[]) => dayStat[])) => void;
};

export const AdminStatsTable = (props: AdminStatsTableProps) => {
    const {
       
        selectedEmployee,
        setSelectedEmployee,
        stats,
        startDate,
        endDate,
        dayStats,
        setDayStats,
    } = props;

   
    const columns = getStatsColumns(startDate, endDate, false);

    const handleCheckboxChange = (id: number, isChecked: boolean, stat: ManagerStatsModel) => {
        setSelectedEmployee(isChecked ? id : -1);
        if (isChecked) {
            const newDays = stat.days
                .filter((day: TimesheetModel) => !day.holidayType && !day.dayBegin && !day.dayEnd)
                .map((day: TimesheetModel) => ({
                    id: day.id,
                    date: new Date(day.date).toDateString(),
                    dayBegin: stat.employee.scheduleBegin,
                    dayEnd: stat.employee.scheduleEnd,
                    workTypeId: null,
                    profileId: id,
                }));
            setDayStats(newDays);
        } else {
            setDayStats([]);
        }
    };

    const isStatSelected = (stat: ManagerStatsModel) => {
        return selectedEmployee === stat.employee.id;
    };

    return (
        <div style={{ margin: "1%" }}>
            <div style={{ height: "30rem" }}>
                <Table>
                    <TableHead>
                        <TableHeadRow>
                            {columns.map((column) => (
                                <TableHeadCell
                                    key={column}
                                    style={{
                                        width: column === "Selected" ? "25px" : "50px",
                                        textAlign: "center",
                                        borderRight: "1px solid #e0e0e0",
                                    }}
                                >
                                    {column}
                                </TableHeadCell>
                            ))}
                        </TableHeadRow>
                    </TableHead>
                    <TableBody>
                        {stats.map((stat: ManagerStatsModel) => (
                            <TableBodyRow key={`key-${stat.employee.marca}-${stat.employee.email}`}>
                                <TableBodyCell
                                    style={{
                                        width: "25px",
                                        textAlign: "center",
                                        borderRight: "1px solid #e0e0e0",
                                    }}
                                >
                                    <Checkbox
                                        checked={isStatSelected(stat)}
                                        onChange={(e) => {
                                            handleCheckboxChange(stat.employee.id, e.checked, stat);
                                        }}
                                    />
                                </TableBodyCell>
                                <TableBodyCell
                                    style={{
                                        width: "50px",
                                        textAlign: "center",
                                        borderRight: "1px solid #e0e0e0",
                                    }}
                                >
                                    {stat.employee.fullName}
                                </TableBodyCell>
                                {stat.days.map((day: TimesheetModel) => (
                                    <TableBodyCell
                                        key={`key-${day.date}`}
                                        style={{
                                            textAlign: "center",
                                            borderRight: "1px solid #e0e0e0",
                                            ...getCellStyles(isStatSelected(stat), day),
                                        }}
                                    >
                                        {CustomCell(day)}
                                    </TableBodyCell>
                                ))}
                            </TableBodyRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
           
        </div>
    );
};

const CustomCell = (data: TimesheetModel) => {
    if (data.id === null && data.holidayType === null) {
        return <span>-</span>;
    }

    if (data.holidayType?.shortName) {
        return <span>{data.holidayType.shortName}</span>;
    }

    if (data.dayBegin && data.dayEnd) {
        return (
            <span>
                {" "}
                {data.dayBegin.slice(0, -3)} - {data.dayEnd.slice(0, -3)}
            </span>
        );
    }

    return <div>-</div>;
};
