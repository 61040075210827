import { FormikProps } from "formik";
import { EmployeeFormFields } from "../../utils/employeesPageUtils";
import { Autocomplete, FormField, FormLayout, Switch } from "@tag/tag-components-react-v4";
import { useGetActivities } from "../../api/useGetActivites";
import { ActivityModel } from "../../models/ActivityModel";
import { useEffect } from "react";
import { useGetEmployeeTypes } from "../../api/EmployeesApi/useGetEmployeeTypes";
import { EmployeeTypeModel } from "../../models/EmployeeTypeModel";
import { isRDEmployee } from "../../utils/checkUtils";

export const RDEligibilityForm = ({ formik }: { formik: FormikProps<EmployeeFormFields> }) => {
    const { data: activitiesData, isLoading: isActivitiesLoading } = useGetActivities();
    const { data: employeeTypesData, isLoading: isLoadingEmployeeTypes } = useGetEmployeeTypes();

    useEffect(() => {
        // Set activityTypeId to null if isEligible is false
        if (!isRDEmployee(formik.values.employeeTypeId)) {
            formik.setFieldValue("activityTypeId", null);
        }
    }, [formik.values.employeeTypeId]);

    return (
        <FormLayout
            height="300px"
            columns={2}
            style={{ padding: "10px 10px", rowGap: "0px", margin: "0px 10px" }}
        >
            <FormField
                label="Employee Type"
                editor={
                    <Autocomplete
                        {...formik.getFieldProps("employeeTypeId")}
                        textField={"type"}
                        valueField={"id"}
                        loadingElement={isActivitiesLoading}
                        clearButton
                        style={{ width: "100%" }}
                        data={
                            employeeTypesData
                                ? employeeTypesData.map((t: EmployeeTypeModel) => ({
                                      id: t.id,
                                      type: t.type,
                                  }))
                                : []
                        }
                    />
                }
                invalid={!!(formik.errors.employeeTypeId && formik.touched.employeeTypeId)}
                validationErrors={formik.errors.employeeTypeId}
                
            />
            <FormField
                label="Activity"
                editor={
                    <Autocomplete
                        {...formik.getFieldProps("activityTypeId")}
                        style={{ width: "100%" }}
                        textField={"name"}
                        valueField={"id"}
                        data={
                            activitiesData
                                ? activitiesData.map((activity: ActivityModel) => ({
                                      id: activity.id,
                                      name: activity.descriptionEN,
                                  }))
                                : []
                        }
                        disabled={!isRDEmployee(formik.values.employeeTypeId)}
                        loadingElement={isActivitiesLoading}
                        clearButton
                    />
                }
                invalid={!!(formik.errors.activityTypeId && formik.touched.activityTypeId)}
                validationErrors={formik.errors.activityTypeId}
            />
        </FormLayout>
    );
};
