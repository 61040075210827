import { LeaderReportGenerator } from "../components/TeamLead/LeaderReportGenerator";
import { LeaderBadApplesView } from "../components/TeamLead/LeaderBadApplesView";
import { LeaderTeamStats } from "../components/TeamLead/LeaderTeamStats";

export const LeaderPage = () => {
    
    return (
        <div>
            <LeaderBadApplesView />
            <LeaderTeamStats />
        </div>
    );
};
