import { RDEmployeeStats } from "../components/RDReport/RDEmployeeStats";
import { RaportGenerators } from "../components/RDReport/ReportGenerators";

export const RDReportPage = () => {
    return (
        <div>
            <RaportGenerators />
			<RDEmployeeStats />
        </div>
    );
};
