import {
    Table,
    TableBody,
    TableBodyCell,
    TableBodyRow,
    TableHead,
    TableHeadCell,
    TableHeadRow,
    Tooltip,
} from "@tag/tag-components-react-v4";
import {
    getActivityNameById,
    getApproverNamesForDays,
    getDivisionName,
    getPendingActivities,
    getStatsColumns,
    isAllDaysApproved,
} from "../../utils/managerUtils";
import { TimesheetModel } from "../../models/TimesheetModel";
import { getDifHours } from "../../utils/dateUtils";
import { Circle3QuarterFilled, CircleTickFilled } from "@tag/tag-icons";
import dayjs from "dayjs";
import { ActivityModel } from "../../models/ActivityModel";
import { useGetActivities } from "../../api/useGetActivites";

export type RDEmployeeStatsTableProps = {
    startDate: Date;
    endDate: Date;
    stats: any;
    divisions?: any[];
};

export const RDEmployeeStatsTable = (props: RDEmployeeStatsTableProps) => {
    const { startDate, endDate, stats, divisions } = props;
    const { data: activities } = useGetActivities();

    const columns = getStatsColumns(startDate, endDate, true);
    return (
        <div style={{ margin: "1%" }}>
            <div style={{ height: "30rem" }}>
                <Table>
                    <TableHead>
                        <TableHeadRow>
                            {columns.map((column) => (
                                <TableHeadCell
                                    key={column}
                                    style={{
                                        width: column === "Selected" ? "25px" : "50px",
                                        textAlign: "center",
                                        borderRight: "1px solid #e0e0e0",
                                    }}
                                >
                                    {column}
                                </TableHeadCell>
                            ))}
                        </TableHeadRow>
                    </TableHead>
                    <TableBody>
                        {stats.items.map((stat: any) => (
                            <TableBodyRow key={`key-${stat.employee.marca}-${stat.employee.email}`}>
                                <TableBodyCell
                                    style={{
                                        width: "50px",
                                        textAlign: "center",
                                        borderRight: "1px solid #e0e0e0",
                                    }}
                                >
                                    {stat.employee.fullName}
                                </TableBodyCell>
                                <TableBodyCell
                                    style={{
                                        width: "25px",
                                        textAlign: "center",
                                        borderRight: "1px solid #e0e0e0",
                                    }}
                                >
                                    {getDivisionName(stat.employee.divisionId, divisions)}
                                </TableBodyCell>
                                {stat.days.map((day: TimesheetModel) => (
                                    <TableBodyCell
                                        key={`key-${day.date}`}
                                        style={{
                                            textAlign: "center",
                                            borderRight: "1px solid #e0e0e0",
                                        }}
                                    >
                                        {CustomCell(day, activities ?? [])}
                                    </TableBodyCell>
                                ))}
                            </TableBodyRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </div>
    );
};
const CustomCell = (data: TimesheetModel, activities: ActivityModel[]) => {
    // ✅ Ensure hook is always called

    // ✅ Default empty state
    if (data.id === null && data.holidayType === null) {
        return <span>-</span>;
    }

    if (data.holidayType?.shortName) {
        return <span>{data.holidayType.shortName}</span>;
    }

    if (data.dayBegin && data.dayEnd) {
        return (
            <span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                {getDifHours(data.dayBegin, data.dayEnd)}
                {isAllDaysApproved(data) ? (
                    <Tooltip
                        tooltipFor={
                            <CircleTickFilled
                                accent="green"
                                size="small"
                                style={{ width: "24px" }}
                            />
                        }
                    >
                        {getApproverNamesForDays(data).map((approver, index) => (
                            <div key={`${index}-${approver.approvedDate}`}>
                                {`Activity: ${getActivityNameById(
                                    approver.activityId,
                                    activities ?? [],
                                )} - ${approver.hoursPerActivity}h, Approved by: ${
                                    approver.approverName
                                } on ${dayjs(approver.approvedDate).format("DD-MM-YYYY")}`}
                            </div>
                        ))}
                    </Tooltip>
                ) : (
                    <Tooltip
                        tooltipFor={<Circle3QuarterFilled size="small" style={{ width: "20px" }} />}
                    >
                        {getPendingActivities(data).map((activity, index) => (
                            <div key={`${index}-${activity.activityId}`}>
                                {`Pending-Activity: ${getActivityNameById(
                                    activity.activityId,
                                    activities ?? [],
                                )} - ${activity.hoursPerActivity}h`}
                            </div>
                        ))}
                    </Tooltip>
                )}
            </span>
        );
    }

    // return <div>-</div>;
};