import { useMutation } from "@tanstack/react-query";
import { baseURL } from "../../constants";

const updateMyProfile = async (
    begin: string,
    end: string,
    token: string,
    activityId?: number,
): Promise<any> => {
    const body = { scheduleBegin: begin, scheduleEnd: end, activitytypeId: activityId };
    const response = await fetch(`${baseURL}/api/Profile/UpdateMyProfile`, {
        method: "PATCH", // *GET, POST, PUT, DELETE, etc.
        headers: {
            "Content-Type": "application/json",

            Authorization: "Bearer " + token,
            // "Access-Control-Allow-Origin": `${baseURL}`,
        },
        body: JSON.stringify(body), // body data type must match "Content-Type" header
    });
    if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Update failed: ${response.status} ${errorText}`);
    }

    return await response.status;
};

export const useUpdateMyProfile = () => {
    return useMutation({
        mutationFn: ({
            begin,
            end,
            activityId,
            token,
        }: {
            begin: string;
            end: string;
            token: string;
            activityId?: number;
        }) => updateMyProfile(begin, end, token, activityId),
    });
};
