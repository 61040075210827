import { CSSProperties } from "react";
import { getHolidayName } from "./holidayUtils";
import { ProfileModel } from "../models/ProfileModel";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { isAfterLast3rdWorkingDay, isFutureMonth, isPastMonth } from "./dateUtils";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { number } from "echarts";
import { debug } from "console";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export type Activity = {
	selectedActivityId?: number;
	type: string;
	days: {numberOfHours: number, isActivityApprovedForDay: boolean}[];
  activityTypeId?: number;
  isRD?: boolean;
};

export type IHoliday = {
	holidayTypeId: number;
  days: Date[];
	description: string;
	type?: string;
}
export type ITimesheet = {
	activities: Activity[];
	holidays: IHoliday[];
}


export const mapDataToActivities = (data: ITimesheet, profile: ProfileModel, setIsWeekFilled: (e: boolean) => void, columns: any[]): ITimesheet => {
  const activitiesMapped: Activity[] = [];

  // Helper function to map days to weekdays (Monday to Friday)
  const mapDaysToWeekdays = (days: { weekDay: string; numberOfHours: number; isActivityApprovedForDay: boolean }[]) => {
const weekdays: { numberOfHours: number; isActivityApprovedForDay: boolean }[] = Array.from({ length: 5 }, () => ({
  numberOfHours: 0,
  isActivityApprovedForDay: false,
}));
    days.forEach((day) => {
  // Parse the date correctly from ISO format
  const weekDay = dayjs(day.weekDay,'Do MMM YYYY').toDate();

  // Get the numeric day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  let weekdayIndex = weekDay.getDay();

  // Convert: Monday = 0, Tuesday = 1, ..., Friday = 4
  weekdayIndex = weekdayIndex - 1; // Shift Monday to 0
  if (weekdayIndex === -1) weekdayIndex = 6; // If it was Sunday (0), map it to Saturday (6)


  if (weekdayIndex >= 0 && weekdayIndex <= 4) { // Ensure it's within Mon-Fri
    weekdays[weekdayIndex] = {
      numberOfHours: day.numberOfHours ?? 0,
      isActivityApprovedForDay: day.isActivityApprovedForDay
    };
  }
});



    return weekdays;
  };

  // Map activities
  if (data.activities && Array.isArray(data.activities)) {
    data.activities.forEach((activity) => {
      const activityType = activity.activityTypeId === profile.activityTypeId ? "main" : "activity";

    
      const mappedDays = mapDaysToWeekdays( 
        activity.days.map((d: any, index) =>{
          const dayIndex = columns.indexOf(dayjs(d.date).format("Do MMM YYYY"));
          return ({
          weekDay: columns[dayIndex], // Assuming columns contain the weekdays
          numberOfHours: d.numberOfHours,
          isActivityApprovedForDay: d.isActivityApprovedForDay
        })})
      );
      activitiesMapped.push({
        selectedActivityId: activity.activityTypeId,
        type: activityType,
        days: mappedDays, // Store the updated structure
      });
    });

    setIsWeekFilled(activitiesMapped.length > 0);
  } else {
    setIsWeekFilled(false);
  }


  // Sort activities to place "main" type first
  activitiesMapped.sort((a, b) => {
    if (a.type === "main") return -1;
    if (b.type === "main") return 1;
    return 0;
  });

  // Map holidays
  const holidaysMapped: IHoliday[] = [];
  if (data.holidays && Array.isArray(data.holidays)) {
    data.holidays.forEach((holiday) => {
      holidaysMapped.push({
        type: "holiday",
        holidayTypeId: holiday.holidayTypeId,
        days: holiday.days.map((day) => new Date(day)), // Convert strings to Date objects
        description: getHolidayName(holiday.holidayTypeId), // Get holiday description
      });
    });
  }

  return {
    activities: activitiesMapped,
    holidays: holidaysMapped,
  };
};


export const calculateRowTotal = (row: { days?: { numberOfHours: number; isActivityApprovedForDay: boolean }[] | any[]; type?: string }, profile: ProfileModel) => {
  if (row.type === "holiday") {
    // For holiday rows, calculate the total based on the number of days in the row
    return Array.isArray(row.days) ? row.days.length * (profile.workingHours ?? 0) : 0;
  }
  if (row.type === "main") {
    // For the main activity or activity rows, return the sum of the numberOfHours
    return (row.days ?? []).reduce((sum, day) => sum + (day.numberOfHours || 0), 0);
  }
  // For all other rows, sum up the days normally
  return (row.days ?? []).reduce((sum, val) => sum + (val.numberOfHours || 0), 0);
};




export const calculateColumnTotals = (
  timesheet: ITimesheet,
  columns: any[],
  profile: ProfileModel
) => {
const totals = Array.from({ length: columns.length }, () => 0);

  // Calculate hours for holidays
  if (timesheet.holidays.length > 0 && profile?.workingHours) {
    timesheet.holidays.forEach((holiday) => {
      holiday.days.forEach((holidayDate) => {
        const holidayLocalDate = dayjs.utc(holidayDate).local().format("Do MMM YYYY");
        const columnIndex = columns.findIndex((column) => column === holidayLocalDate);

        if (columnIndex !== -1) {
          totals[columnIndex] += profile.workingHours; // Add holiday hours to the respective day
        }
      });
    });
  }

  // Calculate hours for activities
  timesheet.activities.forEach((row) => {

    if (!Array.isArray(row.days)) {
      console.warn("row.days is not an array:", row.days);
      return; // Skip this row if days is not an array
    }

    row.days.forEach((value: { numberOfHours: number; isActivityApprovedForDay: boolean }, index: number) => {
      totals[index] += value.numberOfHours || 0;
    });
  });

  return totals;
};




export const handleActivityChange = (
  e: any,
  rowIndex: number,
  timesheet: ITimesheet,
  setTimesheet: (e: any) => void,
  toast: any
) => {
  const selectedValue = e.target.value;
  const isAlreadySelected = timesheet.activities.some(
    (activity: Activity, index: number) =>
      activity.selectedActivityId === selectedValue && index !== rowIndex
  );

  if (isAlreadySelected) {
    toast("This activity is already selected. Please choose another one.", {
      toastType: "warning",
    });
    return;
  }

  const updatedActivities = [...timesheet.activities];
  if (updatedActivities[rowIndex]) {
    updatedActivities[rowIndex].selectedActivityId = selectedValue;
  }
  setTimesheet({ activities: updatedActivities, holidays: timesheet.holidays });
};

export const handleInputChange = (
  rowIndex: number,
  dayIndex: number,
  value: string,
  timesheet: ITimesheet,
  profile: ProfileModel | null,
  setTimesheet: (e: any) => void,
) => {
  const updatedActivities = [...timesheet.activities];
  const inputValue = Math.max(0, parseInt(value) || 0); // Ensure min value is 0

  const currentDayTotal = updatedActivities.reduce((sum, row, index) => {
    if (row.type !== "main" || index === rowIndex) return sum + row.days[dayIndex].numberOfHours;
    return sum;
  }, 0);

  const maxAllowed = (profile?.workingHours ?? 8) - currentDayTotal + updatedActivities[rowIndex].days[dayIndex].numberOfHours;
  const finalValue = Math.min(inputValue, maxAllowed);

  // Adjust Main Activity
  const mainActivityIndex = updatedActivities.findIndex((row) => row.type === "main");
  if (mainActivityIndex !== -1 && rowIndex !== mainActivityIndex) {
    const difference = finalValue - updatedActivities[rowIndex].days[dayIndex].numberOfHours;
    updatedActivities[mainActivityIndex].days[dayIndex].numberOfHours= Math.max(
      updatedActivities[mainActivityIndex].days[dayIndex].numberOfHours - difference,
      0
    );
  }

  updatedActivities[rowIndex].days[dayIndex].numberOfHours = finalValue;
  setTimesheet({ activities: updatedActivities, holidays: timesheet.holidays });
};

export const handleDeleteRow = (
  rowIndex: number,
  setTimesheet: (e: any) => void, 
  columns: string[], // Pass columns containing dates for mapping dayIndex
  profile: ProfileModel | null,
) => {
  setTimesheet((prevTimesheet: ITimesheet) => {

    // Filter out the row to delete
    const updatedActivities = prevTimesheet.activities.filter(
      (_, index) => index !== rowIndex
    );

    // Check if there are any activities left and if the first is the "main" activity
    if (updatedActivities.length > 0 && updatedActivities[0].type === "main") {
      // const newTotals = calculateNewTotals(updatedActivities);

      // Extract holiday days into a Set for quick lookup
      const holidayDays = new Set(
        prevTimesheet.holidays.flatMap((holiday: any) =>
          holiday.days.map((day: string) => dayjs(day).format("Do MMM YYYY")) // Normalize to "Do MMM YYYY" format
        )
      );

 
    updatedActivities[0].days = updatedActivities[0].days.map((day, dayIndex) => {
      // Skip days that have a holiday
      if (holidayDays.has(columns[dayIndex])) {
        return day; // Do not modify hours for holidays
      }

      // Calculate total hours already assigned for this day, including the current main activity
      const totalAssignedHours = updatedActivities.reduce(
        (sum, activity) => sum + (activity.days[dayIndex].numberOfHours || 0),
        0
      );

      // Calculate remaining hours for the day
      const remainingHours = Math.max(0, (profile?.workingHours ?? 0) - totalAssignedHours);

      // Add only the remaining hours to the main activity
      return {
        ...day,
        numberOfHours: Math.min(
          day.numberOfHours + remainingHours,
          (profile?.workingHours ?? 0) - totalAssignedHours + day.numberOfHours
        )
      };
    });}

    // Return the updated timesheet, ensuring it has the full ITimesheet structure
    return {
      activities: updatedActivities, // Updated activities
      holidays: prevTimesheet.holidays, // Keep holidays unchanged
    };
  });
};









export const calculateTotalSum = (
  columnsTotals: number[]
) => {
  return columnsTotals.reduce((sum, value) => sum + value, 0);
};

export const fillWeek = (
    profile: ProfileModel | null,
    timesheet: ITimesheet,
    columns: any[],
    setTimesheet: (e: any) => void,
    setIsWeekFilled: (e: any) => void
) => {
    if (!profile?.workingHours) return;

    // Clone current activities to avoid direct mutation
    const updatedActivities = [...timesheet.activities];

    // Check if "main" activity exists
    let mainActivity = updatedActivities.find((activity) => activity.type === "main");

    if (!mainActivity) {
        // Add a new "main" activity if it doesn't exist
        mainActivity = {
            type: "main",
            selectedActivityId: profile.activityTypeId,
            days: Array(columns.length).fill({ numberOfHours: 0, isActivityApprovedForDay: false }), // Initialize all days with 0 hours
        };
        updatedActivities.push(mainActivity);
    }

    // Create a set of holiday days for quick lookup
    const holidayDays = new Set(
        timesheet.holidays.flatMap((holiday) =>
            holiday.days.map((day) => dayjs(day).format("Do MMM YYYY")) 
        )
    );

    // Fill the week for the "main" activity
    mainActivity.days = mainActivity.days.map((day, dayIndex) => {
         
        // Skip days that have a holiday
        if (holidayDays.has(columns[dayIndex])) {
          
            return day; // Do not modify hours for holidays
        }

        // Calculate total hours already assigned for this day, including the current main activity
        const totalAssignedHours = updatedActivities.reduce(
            (sum, activity) => sum + (activity.days[dayIndex].numberOfHours || 0),
            0
        );

        // Calculate remaining hours for the day
        const remainingHours = Math.max(0, profile.workingHours - totalAssignedHours);

        // Add only the remaining hours to the main activity
        return {
            ...day,
            numberOfHours: Math.min(
                day.numberOfHours + remainingHours,
                profile.workingHours - totalAssignedHours + day.numberOfHours
            )
        };
    });

    // Update totals and set the new state
    setTimesheet({ ...timesheet, activities: updatedActivities });
    setIsWeekFilled(true);
};




export const addActivity = (setTimesheet: (e: any) => void, timesheet: ITimesheet) => {
  const newRow = {
    selectedActivityId: undefined,
    type: "other",
    days: Array.from({ length: 5 }, () => ({ numberOfHours: 0, isActivityApprovedForDay: false })), 
  };

  setTimesheet((prevTimesheet: ITimesheet) => ({
    ...prevTimesheet, // ✅ Keep other properties unchanged
    activities: [...prevTimesheet.activities, newRow],
  }));
};


export  const areButtonsDisabled = (timesheet: ITimesheet,  profile: ProfileModel| null,  columns: any[], startDate: Date, endDate: Date) => {
    let buttonsStates = {
      fillWeek: false,
      addActivity: false,
      save: false,
    }     
      const hasHolidays = timesheet.holidays.length > 0;

        const isAllDaysHoliday =
            hasHolidays &&
            columns.every((column) =>
          timesheet.holidays.some((holiday) =>
              holiday.days.some((day) => dayjs(day).format("Do MMM YYYY") === column && profile?.workingHours === 8)
          )
            );
         const ismainActivityFilled = timesheet.activities.some(
            (activity) =>
              activity.type === "main" &&
              activity.days.some((entry) => entry.numberOfHours === profile?.workingHours),
          );
         if(isAllWeekApproved(timesheet)) {
          console.log("All days are approved");
         return  { fillWeek: true, addActivity: true, save: true };
        };
          if(isPastMonth(startDate, endDate) || isFutureMonth(startDate, endDate) ||  isAfterLast3rdWorkingDay()) {
            return { fillWeek: true, addActivity: true, save: true };
          }
        if (isAllDaysHoliday) {
          buttonsStates = { fillWeek: true, addActivity: true, save: true };
        }
        if(ismainActivityFilled) {
          buttonsStates = { fillWeek: true, addActivity: false, save: false };
        }
       
        return buttonsStates;
    };

export const isAllWeekApproved = (timesheet: ITimesheet) : boolean => {
return timesheet.activities.length > 0 &&
  timesheet.activities.every((activity) =>
    activity.days.filter((day) => day.numberOfHours > 0).every((day) => day.isActivityApprovedForDay)
  );

  
};
