// export const AdminHolidays = [
//     {
//         id: 1,
//         name: "CO – concediu de odihna",
//     },
//     {
//         id: 2,
//         name: "CM - Concediu medical",
//     },
//     {
//         id: 3,
//         name: "CPL – Concediu cu plata",
//     },
//     { id: 4, name: "CFS - Concediu fara salar" },
//     { id: 5, name: "AN - Absenta nemotivata" },
//     { id: 6, name: "IP- Invoire platita" },
//     { id: 7, name: "CIC - Concediu Ingrijire Copil" },
//     { id: 8, name: "D – Delegatie" },
//     { id: 9, name: "BB – Big Break" },
//     { id: 10, name: "CHR - Charity Day" },
//     { id: 69, name: "Sarbatoare nationala" },
// ];

export const getHolidayName = (id: number) => {
	switch (id) {
		case 1:
			return "CO";
		case 2:
			return "CM";
		case 3:
			return "CPL";
		case 4:
			return "CFS";
		case 5:
			return "AN";
		case 6:
			return "IP";
		case 7:
			return "CIC";
		case 8:
			return "D";
		case 9:
			return "BB";
		case 10:
			return "CHR";
		case 69:
			return "SN";
		default:
			return "";
	}
}