export const baseURL = process.env.REACT_APP_BASE_API;

export const TOAST_CONTAINER_ID = "timesheet-toast-container";

export const REQUIRED_VALIDATION_MESSAGE = "Required";

export const INFO_NO_CHANGES_DETECTED = "No changes detected to the Day Off";

export const SUCCESS_ADDUPDATE_DAY_OFF = "Successfully added or updated Day Off";
export const SUCCESS_DELETED_DAY_OFF = "Successfully deleted Day Off";
export const ERROR_COULDNT_ADDUPDATE_DAY_OFF = "Couldn't add or update Day Off";
export const ERROR_COULDNT_DELETE_DAY_OFF = "Couldn't delete Day Off";

export const SUCCESS_ADDUPDATE_TIMESHEET = "Successfully added or updated Timesheet";
export const SUCCESS_ADDUPDATE_WORKTYPE = "Successfully added or updated work type";
export const SUCCESS_FILL_WEEK = "Successfully filled week";
export const ERROR_COULDNT_ADDUPDATE_TIMESHEET = "Couldn't add or update Timesheet";
export const ERROR_COULDNT_ADDUPDATE_TIMESHEET_WORKHOURS =
    "Couldn't save Timesheet. Selected hours don't match the Working Hours or are not within the department's Working Hours.";
export const ERROR_COULDNT_ADDUPDATE_WORKTYPE = "Couldn't add or update work type";
export const ERROR_COULDNT_FILL_WEEK = "Couldn't fill week";

export const AdminHolidays = [
    {
        id: 1,
        name: "CO – concediu de odihna",
    },
    {
        id: 2,
        name: "CM - Concediu medical",
    },
    {
        id: 3,
        name: "CPL – Concediu cu plata",
    },
    { id: 4, name: "CFS - Concediu fara salar" },
    { id: 5, name: "AN - Absenta nemotivata" },
    { id: 6, name: "IP- Invoire platita" },
    { id: 7, name: "CIC - Concediu Ingrijire Copil" },
    { id: 8, name: "D – Delegatie" },
    { id: 9, name: "BB – Big Break" },
    { id: 10, name: "CHR - Charity Day" },
    { id: 69, name: "Sarbatoare nationala" },
];
export const SUCCESS_ADDUPDATE_EMPLOYEE_DETAILS = "Successfully added or updated Employee details";
export const SUCCESS_UPDATE_WORKLOCATION = "Successfully updated Employee's Work Location";
export const SUCCESS_UPDATE_WORKHOURS = "Successfully updated Employee's Work Hours";
export const SUCCESS_DELETE_EMPLOYEE = "Successfully deleted Employee";
export const ERROR_COULDNT_ADDUPDATE_EMPLOYEE_DETAILS = "Couldn't add or update Employee details";
export const ERROR_COULDNT_UPDATE_WORKLOCATION = "Couldn't update Employee's Work Location";
export const ERROR_COULDNT_UPDATE_WORKHOURS = "Couldn't update Employee's Work Hours";
export const ERROR_COULDNT_DELETE_EMPLOYEE = "Couldn't delete Employee";
export const ERROR_COULDNT_APPROVE_ALL_TIMESHEET = "Couldn't approve all Timesheet";