import { CSSProperties } from "react";
import { ActivityModel } from "../../models/ActivityModel";
import { isAfterLast3rdWorkingDay, isCurrentMonth } from "../../utils/dateUtils";
import { ProfileModel } from "../../models/ProfileModel";
import { calculateTotalSum } from "../../utils/rdTimesheetUtils";

export const divStyle :CSSProperties= {
	 marginTop: "1%",
    display: "flex",
    gap: "10px",
    flexDirection: "column",
    marginRight: "1%",
}

export const tableHeadStyle: CSSProperties = { position: "sticky", top: "0", zIndex: 1 }

export const tableBodyStyle: CSSProperties = {
	overflowY: "auto",
	border: "1px solid lightgrey",
	overflowX: "hidden",
}

export const h5Style: CSSProperties = {
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
}
export const cellStyle: CSSProperties = {
	borderRight: "1px solid lightgrey",
	textAlign: "center",
	width: "150px", // Set the same width for all cells
	// display: "flex",
	// flexDirection: "row",
};

export const basicInputStyle: CSSProperties = {
	textAlign: "center",
	color: "black",
	width: "100px",
}

export const tableBodyRowStyle = (activitiesData: ActivityModel[] | undefined, row: any): CSSProperties => ({
	maxHeight: "40px",
	backgroundColor: activitiesData?.some(
		(activity: ActivityModel) =>
			activity.id === row.selectedActivityId &&
			!activity.rd,
	)
		? "#FDF6E7"
		: "transparent",
});

export const inputStyleDays= (row: any, holidayDays: Set<string>, columns: string[], dayIndex: number, isActivityApprovedForDay: boolean): CSSProperties => ({
textAlign: "center",
color: `${
	(row.type === "holiday" ||
		row.type === "main" ||
		holidayDays.has(
			columns[dayIndex],
		) ||
		!isCurrentMonth(
			columns[dayIndex],
		) ||
		isAfterLast3rdWorkingDay() || isActivityApprovedForDay) &&
	"black"
}`,
width: "100px",
})

export const columnTotalsCellStyle= (profile: ProfileModel| null, total: number): CSSProperties => ({
	color: `${
		total > (profile?.workingHours ?? 0) || total === 0
			? "red"
			: "black"
	}`,
})

export const totalSummCellStyle= (profile: ProfileModel| null, columnTotals: number[]): CSSProperties => ({
	color: `${
		calculateTotalSum(columnTotals) >
		5 * (profile?.workingHours ?? 0)
			? "red"
			: "black"
	}`,
});