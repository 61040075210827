import { HolidaysAdminModel } from "../models/HolidaysAdminModel";
import { ManagerStatsModel } from "../models/ManagerStatsModel";
import { ValidationManyModel } from "../models/ValidationManyModel";
import { useGetDivisions } from "../api/useGetDivisions";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { TimesheetModel } from "../models/TimesheetModel";
import { useGetActivities } from "../api/useGetActivites";
import { ActivityModel } from "../models/ActivityModel";
dayjs.extend(advancedFormat);
dayjs.extend(isSameOrBefore);
export const sortManagerHolidays = (holidays: HolidaysAdminModel[]): HolidaysAdminModel[] => {
    return holidays.sort((a, b) => a.profile.fullName.localeCompare(b.profile.fullName));
};

export const filterHolidays = (
    holidays: HolidaysAdminModel[],
    searchCriteria: string,
): HolidaysAdminModel[] => {
    return holidays?.filter(
        (a) =>
            a.profile.firstName.toLowerCase().indexOf(searchCriteria.toLowerCase()) > -1 ||
            a.profile.lastName.toLowerCase().indexOf(searchCriteria.toLowerCase()) > -1 ||
            a.profile.email.toLowerCase().indexOf(searchCriteria.toLowerCase()) > -1 ||
            a.profile.marca.toLowerCase().indexOf(searchCriteria.toLowerCase()) > -1,
    );
};
type EmployeeForSearch = {
    id: number;
    fullName: string;
    email: string;
};
export const getEmployeesForSearch = (stats: ManagerStatsModel[]): EmployeeForSearch[] => {
    return stats
        .filter((stat) => !stat.employee.isFired)
        .map((stat) => {
            return {
                id: stat.employee.id,
                fullName: stat.employee.fullName,
                email: stat.employee.email,
            };
        });
};

export const getHolidayPages = (
    holidays: HolidaysAdminModel[],
    page: number,
    pageSize: number,
): any[] => {
    return holidays.slice((page - 1) * pageSize, page * pageSize).map((obj) => ({
        ...obj,
        begin: obj.holiday.begin,
        end: obj.holiday.end,
        typeName: obj.holiday.type ? obj.holiday.type.shortName : "",
        accent: obj.holiday.type ? obj.holiday.type.accent : "",
        icon: obj.holiday.type ? obj.holiday.type.icon : "",
        model: obj,
        profile: obj.profile.fullName,
        id: obj.holiday.id,
    }));
};

export const holidayOrNot = (DataToValidate: ValidationManyModel[]) => {
    return DataToValidate.filter((data) => data.holidayType);
};

export const getStatsForManagerWithUiKey = (stats: ManagerStatsModel[]) => {
    return stats.map((stat) => {
        const { id: employeeId } = stat.employee;
        return {
            ...stat,
            days: stat.days.map((day: any) => ({
                ...day,
                uiKey: `${new Date(day.date).getDate()}${employeeId}`,
            })),
        };
    });
};

export const getStatsColumns = (startDate: Date, endDate: Date, forFinance?: boolean) => {
    const columns = forFinance ? ["Employee", "Division"] : ["Selected", "Employee"];
    let currentDate = dayjs(startDate);

    while (currentDate.isSameOrBefore(dayjs(endDate), "day")) {
        const formattedDate = currentDate.format("Do MMM YYYY"); // Adds ordinal suffix, short month, and year
        columns.push(formattedDate);
        currentDate = currentDate.add(1, "day");
    }

    return columns;
};

export const getStyles = (day: any, uiSelect: any) => {
    if (uiSelect.includes(day.uiKey)) {
        return {
            color: "#4b4e52",
            backgroundColor: "#8ec5fc",
            fontSize: "0.9rem",
        };
    } else if (day.timesheetValidation?.statusLiteral === "Rejected") {
        return {
            color: "white",
            backgroundColor: "#e5173f",
            fontSize: "0.9rem",
        };
    } else if (
        day.timesheetValidation?.statusLiteral === "Approved" ||
        day.holidayType?.name === "Sarbatoare nationala"
    ) {
        return {
            color: "white",
            backgroundColor: "#099",
            fontSize: "0.9rem",
        };
    } else {
        return {
            color: "#4b4e52",
            fontSize: "0.9rem",
        };
    }
};

export const getValidationStatsPage = (
    data: ManagerStatsModel[],
    page: number,
    pageSize: number,
): ManagerStatsModel[] => {
    // Calculate indices and slice
    const startIndex = (page - 1) * pageSize;
    const endIndex = page * pageSize;

    return data.slice(startIndex, endIndex).map((obj) => ({ ...obj }));
};

export const getDivisionName = (divisionId: number, divisions?: any[]) => {
    const division = divisions?.find((division: any) => division.id === divisionId);
    return division ? `${division.shortName}` : "-";
};

export const isAllDaysApproved = (day: TimesheetModel) => {
    return day.rdActivities.every((activity) => activity.isApproved);
};

export const getApproverNamesForDays = (day: TimesheetModel) => {
    return day.rdActivities
        .filter((activity) => activity.isApproved)
        .map((activity) => ({
            activityId: activity.activityTypeId,
            approverName: activity.approverName,
            approvedDate: activity.approvedDate,
            hoursPerActivity: activity.hoursPerActivity,
        }));
};

export const getActivityNameById = (activityId: number, activities: ActivityModel[]) => {
    const activity = activities?.find((activity) => activity.id === activityId);
    return activity ? activity.descriptionEN : "-";
};

export const getPendingActivities = (day: TimesheetModel) => {
    return day.rdActivities
        .filter((activity) => !activity.isApproved)
        .map((activity) => ({
            activityId: activity.activityTypeId,
            hoursPerActivity: activity.hoursPerActivity,
			
        }));
};