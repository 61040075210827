import { useQuery } from "@tanstack/react-query";
import { baseURL } from "../constants";
import { IncompleteTimesheetsModel } from "../models/IncompleteTimesheetsModel";

const fetchBadApples = async (
    lastWeekCurrentPage: number,
    lastMonthCurrentPage: number,
    token: string,
    divisionId?: number,
): Promise<IncompleteTimesheetsModel> => {
    const pageSize: number = 5;
    const queryParams = new URLSearchParams({
        lastWeekTake: pageSize.toString(),
        lastWeekSkip: ((lastWeekCurrentPage - 1) * pageSize).toString(),
        thisMonthTake: pageSize.toString(),
        thisMonthSkip: ((lastMonthCurrentPage - 1) * pageSize).toString(),
    });

    if (divisionId) {
        queryParams.append("divisionId", divisionId.toString());
    }

    const response = await fetch(
        `${baseURL}/api/Manager/GetBadApples?${queryParams.toString()}`,
        {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        },
    );

    if (!response.ok) {
        throw new Error(`Failed to fetch bad apples: ${response.statusText}`);
    }

    return await response.json();
};

export const useBadApples = (
    lastWeekCurrentPage: number,
    lastMonthCurrentPage: number,
    token: string,
    divisionId?: number,
) => {
    return useQuery<IncompleteTimesheetsModel, Error>({
        queryKey: ["badApples", lastWeekCurrentPage, lastMonthCurrentPage, divisionId],
        queryFn: () => fetchBadApples(lastWeekCurrentPage, lastMonthCurrentPage, token, divisionId),
        enabled: Boolean(token),
        retry: 1,
    });
};
