import React from "react";
import { IncompleteListModel } from "../../models/IncompleteTimesheetsModel";
import { Alert, Pager, Spinner } from "@tag/tag-components-react-v4";
import { BadApplesList } from "../commons/badApples/BadApplesList";
export type ManagerRDBadApplesTabsProps = {
    isLastWeek: boolean;
    isLoading: boolean;
    error: Error | null;
    badApplesData: IncompleteListModel | undefined;
    page: number;
    setPage: (page: number) => void;
};
export const ManagerRDBadApplesTabs = (props: ManagerRDBadApplesTabsProps) => {
    const { isLastWeek, isLoading, error, badApplesData, page, setPage } = props;

    if (isLoading)
        return (
            <div>
                <Spinner style={{ marginTop: "2%", height: "400px" }}>Loading ...</Spinner>
            </div>
        );
    else if (error)
        return (
            <div>
                <Alert style={{ margin: "2%", width: "fit-content" }} type="error">
                    Error fetching incomplete timesheets! Please <strong>refresh</strong> the page.
                </Alert>
            </div>
        );
    else if (badApplesData && badApplesData.items[0].length > 0) {
        return (
            <div>
                <div style={{ height: "400px" }}>
                    <BadApplesList isLastWeek={isLastWeek} badApples={badApplesData.items[0]} />
                    <Pager
                        page={page}
                        pageCount={Math.ceil(badApplesData.totalCount / 5)}
                        style={{
                            display: "flex",
                            padding: "10px",
                            flexDirection: "row",
                            alignContent: "center",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            width: "340px",
                        }}
                        onPageRequest={(e) => setPage(e.page)}
                    />
                </div>
            </div>
        );
    } else {
        return (
            <div>
                <Alert style={{ margin: "1%", width: "fit-content" }} type="success">
                    No incomplete timesheets found! <strong>This is a very very happy case!</strong>
                </Alert>
            </div>
        );
    }
};
