import { useQuery } from "@tanstack/react-query";
import { baseURL } from "../../constants";
import { getMonday, ParseDate } from "../../utils/dateUtils";
import { ManagerStatsModel } from "../../models/ManagerStatsModel";
import { PaginationModel } from "../../models/PaginationModel";
import { StatsModel } from "../../models/StatsModel";

const fetchValidationStatsForAdmin = async( 
	token: string, 
	begin: Date, 
	end: Date, 
	page: number, 
	pageSize: number ,
	searchCriteria: string, 
	chosenDivision?: number | undefined
): Promise<PaginationModel<ManagerStatsModel>> => {
	const monday = ParseDate(getMonday(begin));
	const friday = ParseDate(end);
	const pageSkip = (page - 1) * pageSize;
	const queryParams = new URLSearchParams({
		take: pageSize.toString(),
		skip: pageSkip.toString(),
		searchCriteria: searchCriteria,
		divisionId: chosenDivision?.toString() || ""
	});
	const url = `${baseURL}/api/Admin/GetValidationStats/${monday}/${friday}?${queryParams.toString()}`;

	const response = await fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json;charset=utf-8",
			Authorization: `Bearer ${token}`,
		},
	});

	if (!response.ok) {
		throw new Error("Failed to fetch stats");
	}

	return await response.json();
}

export const useValidationsStatsForAdmin = (token: string, begin: Date, end: Date, page: number, pageSize: number, searchCriteria: string, enabled?: boolean, chosenDivision?: number) => {
	return useQuery<PaginationModel<ManagerStatsModel>, Error>({
		queryKey: ["validationStats", begin, end, page, pageSize, searchCriteria, chosenDivision],
		queryFn: () => fetchValidationStatsForAdmin(token, begin, end, page, pageSize, searchCriteria, chosenDivision),
		enabled: !!token && !!enabled,
		retry: 1,
	});
}


const fetchStatsForAdmin = async (token: string) => {
	const url = `${baseURL}/api/Admin/Stats`;

	const response = await fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json;charset=utf-8",
			Authorization: `Bearer ${token}`,
		}
	});

	const data = await response.json();
	return data;
}

export const useStatsForAdmin = (token: string) => {
	return useQuery<StatsModel, Error>({
		queryKey: ["stats"],
		queryFn: () => fetchStatsForAdmin(token),
		enabled: !!token,
		retry: 1,
	});
};