import { useMutation, useQueryClient } from "@tanstack/react-query";
import { baseURL, ERROR_COULDNT_APPROVE_ALL_TIMESHEET, TOAST_CONTAINER_ID } from "../../constants";
import { useContext } from "react";
import { TimesheetContext } from "../../contexts/TimesheetContext";

const postApproveAllTimesheet = async (divisionId: number, token: string) => {
	const response = await fetch(`${baseURL}/api/RD/ApproveRDTimesheet?divisionId=${divisionId}`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: `Bearer ${token}`,
		},
	});

	if (!response.ok) throw new Error(ERROR_COULDNT_APPROVE_ALL_TIMESHEET);
	return response.status;
};

export const useApproveAllTimesheet = () => {
	const queryClient = useQueryClient();
	const {accessToken} = useContext(TimesheetContext);
	// const {toast} = useToasts(TOAST_CONTAINER_ID)

	return useMutation({
		mutationKey: ["approveAllTimesheet"],
		mutationFn: ({ divisionId }: { divisionId: number }) => postApproveAllTimesheet(divisionId, accessToken),
		onSuccess: () => {
			queryClient.invalidateQueries();
		},
		// onError: () => {}
	});
};