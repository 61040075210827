import React, { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { TimesheetContext } from '../contexts/TimesheetContext';
import {
    DatePicker,
    DropDownFlex,
    FormField,
    H3,
    Input,
    Label,
    Pill,
    SimpleButton,
    Spinner,
    TimePicker,
    useToasts,
} from "@tag/tag-components-react-v4";
import { ErrorCard } from "../components/commons/ErrorCard";
import { BuildingFilled, HomeFilled, SaveDiskFilled } from "@tag/tag-icons";
import { getDayWorkedHours } from "../utils/TimesheetUtils";
import { TOAST_CONTAINER_ID } from "../constants";
import { useUpdateMyProfile } from "../api/ProfileApi/useMyProfileUpdate";
import { useQueryClient } from "@tanstack/react-query";
import { useGetActivities } from "../api/useGetActivites";
import { ActivityModel } from "../models/ActivityModel";
import { useGetDivisions, useGetSubdivisions } from '../api/useGetDivisions';
import { isRDEmployee } from '../utils/checkUtils';

export const ProfilePage = () => {
    const { profile, accessToken, isProfileLoading } = useContext(TimesheetContext);
    const queryClient = useQueryClient();
    const { toast } = useToasts(TOAST_CONTAINER_ID);
    const [startTime, setStartTime] = useState<string>("");
    const [endTime, setEndTime] = useState<string>("");
    const [activityId, setActivityId] = useState<number>(profile?.activityTypeId ?? 0);
    const { data: activitiesData, isLoading: isActivitiesLoading } = useGetActivities();
    const {data: DivisionData, isLoading: isDivisionLoading} = useGetDivisions();
    const {data: SubdivisionData, isLoading: isSubdivisionLoading} = useGetSubdivisions(profile?.divisionId ?? 0);
    useEffect(() => {
        if (profile) {
            setStartTime(profile?.scheduleBegin?.substring(0, 5) || "");
            setEndTime(profile?.scheduleEnd?.substring(0, 5) || "");
            setActivityId(profile?.activityTypeId ?? 0);
        }
    }, [profile]);
    const office = profile?.workLocationSchedule
        .filter(({ workType }) => workType.id === 1)
        .map(({ dayOfWeek }) => dayOfWeek.substring(0, 3));

    const home = profile?.workLocationSchedule
        .filter(({ workType }) => workType.id === 2)
        .map(({ dayOfWeek }) => dayOfWeek.substring(0, 3));
    const minMaxHours = [
        parseInt((profile?.department.shiftBegin ?? "00:00").split(":")[0]),
        parseInt((profile?.department.shiftEnd ?? "0:00").split(":")[0]),
    ];

    const { mutate: updateMyProfile, isPending, error } = useUpdateMyProfile();
    const handleSave = () => {
        if (!startTime || !endTime) {
            toast("Start and End times must be filled", { toastType: "error" });
            return;
        }

        const isValidHours = getDayWorkedHours(startTime, endTime) === profile?.workingHours;
        if (!isValidHours) {
            toast("The new hours are not the same as the working hours", { toastType: "error" });
            return;
        }
        updateMyProfile(
            { begin: startTime, end: endTime, token: accessToken, activityId: activityId },
            {
                onError: (error) => {
                    toast(`Error updating profile : ${error.message}`, { toastType: "error" });
                },
                onSuccess: (data) => {
                    queryClient.invalidateQueries();
                    toast("Profile updated", { toastType: "success" });
                },
            },
        );
    };
    if (isProfileLoading) return <Spinner>Loading...</Spinner>;
    else if (profile !== null)
        return (
            <div style={{ margin: "2%" }}>
                <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
                    <H3 style={{ fontSize: "1.75rem", fontWeight: "bold" }}>{profile?.fullName}</H3>
                    <Pill
                        variant="outlined"
                        style={{
                            backgroundColor: "#036d8325",
                            color: "#036d83",
                            border: "none",
                            marginTop: "1%",
                            fontSize: "1rem",
                            width: "15rem",
                            height: "2rem",
                            display: "inline-flex",
                            justifyContent: "center",
                        }}
                    >
                        <span style={{ padding: "1%", fontWeight: "500" }}>
                            {profile?.department.name}
                        </span>
                    </Pill>
                    {!!DivisionData && !!profile.divisionId && (
                        <div style={{ display: "flex", gap: "1%", marginTop: "1%" }}>
                            <Pill
                                variant="outlined"
                                style={{
                                    backgroundColor: "#79264d25",
                                    color: "#79264D",
                                    border: "none",
                                    fontSize: "1rem",
                                    width: "10rem",
                                    height: "2rem",
                                    display: "inline-flex",
                                    justifyContent: "center",
                                }}
                            >
                                <span style={{ padding: "1%", fontWeight: "500" }}>
                                    {DivisionData.find((division) => division.id === profile.divisionId)?.name}
                                </span>
                            </Pill>
                        </div>
                    )}
                    {!!SubdivisionData && !!profile.subdivisionId && (
                        <div style={{ display: "flex", gap: "1%", marginTop: "1%" }}>
                            <Pill
                                variant="outlined"
                                style={{
                                    backgroundColor: "#8A3A0025",
                                    color: "#8A3A00",
                                    border: "none",
                                    fontSize: "1rem",
                                    width: "10rem",
                                    height: "2rem",
                                    display: "inline-flex",
                                    justifyContent: "center",
                                }}
                            >
                                <span style={{ padding: "1%", fontWeight: "500" }}>
                                    {SubdivisionData.find((subdivision) => subdivision.id === profile.subdivisionId)?.name}
                                </span>
                            </Pill>
                        </div>
                    )}
                </div>

                <FormField
                    style={{ marginTop: "1%" }}
                    label="Email"
                    disabled
                    editor={
                        <Input value={profile.email} style={{ color: "black", width: "100%" }} />
                    }
                />

                <FormField
                    label="Manager Email"
                    disabled
                    editor={
                        <Input
                            value={profile.managerEmail}
                            style={{ color: "black", width: "100%" }}
                        />
                    }
                />

                <FormField
                    label="Start Date"
                    disabled
                    editor={
                        <DatePicker
                            style={{ width: "100%" }}
                            disabled
                            inputStyle={{ color: "black" }}
                            value={new Date(profile.startDate)}
                        />
                    }
                />
                <FormField
                    label="Working Hours"
                    disabled
                    editor={
                        <Input
                            value={profile.workingHours}
                            style={{ color: "black", width: "100%" }}
                        />
                    }
                />
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <FormField
                            label="Office"
                            disabled
                            editor={
                                <Input value={office} style={{ color: "black", width: "100%" }} />
                            }
                        />
                        <BuildingFilled size="large" />
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {" "}
                        <FormField
                            label="Home"
                            disabled
                            editor={
                                <Input value={home} style={{ color: "black", width: "100%" }} />
                            }
                        />
                        <HomeFilled size="large" />
                    </div>

                    <FormField
                        label="Work Practice"
                        disabled
                        editor={
                            <Input
                                value={profile.workPractice}
                                style={{ color: "black", width: "100%" }}
                            />
                        }
                    />
                    <FormField
                        label="Office Location"
                        disabled
                        editor={
                            <Input
                                value={profile.officeLocation}
                                style={{ color: "black", width: "100%" }}
                            />
                        }
                    />
                </div>
                <div style={{ display: "flex", flexDirection: "row", gap: "16px" }}>
                    <div>
                        <Label>Working Schedule</Label>
                        <div style={{ display: "flex", gap: "12px", marginTop: "1%" }}>
                            <TimePicker
                                id="profile-timepicker1"
                                onChange={(e) => setStartTime(e?.value ?? "")}
                                value={startTime}
                                is24HourFormat
                                showSeconds={false}
                                minHour={minMaxHours[0]}
                                maxHour={minMaxHours[1]}
                                hideNowButton
                                hideConfirmButton={false}
                            />{" "}
                            -{" "}
                            <TimePicker
                                id="profile-timepicker1"
                                onChange={(e) => setEndTime(e.value ?? "")}
                                value={endTime}
                                is24HourFormat
                                showSeconds={false}
                                minHour={minMaxHours[0]}
                                maxHour={minMaxHours[1]}
                                hideNowButton
                                hideConfirmButton={false}
                            />
                        </div>
                    </div>
                    {isRDEmployee(profile.employeeTypeId) && (
                        <div style={{ display: "flex", flexDirection: "column", marginTop: "5px" }}>
                            <Label>Main Activity</Label>
                            <DropDownFlex
                                value={activityId}
                                // clearButton
                                style={{ width: "15rem", marginTop: "1%" }}
                                textField={"name"}
                                valueField={"value"}
                                data={(activitiesData ?? []).map((activity: ActivityModel) => ({
                                    value: activity.id,
                                    name: activity.descriptionEN,
                                }))}
                                loadingElement={
                                    <div
                                        style={{
                                            height: "150px",
                                            width: "200px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Spinner>Waiting for data...</Spinner>
                                    </div>
                                }
                                isLoading={isActivitiesLoading}
                                onChange={(e) => setActivityId(e.item.value)}
                            />
                        </div>
                    )}
                </div>

                <SimpleButton
                    style={{ marginTop: "2%", width: "5rem" }}
                    accent="teal"
                    startIcon={<SaveDiskFilled />}
                    onClick={handleSave}
                    disabled={
                        !startTime ||
                        !endTime ||
                        getDayWorkedHours(startTime, endTime) !== profile?.workingHours ||
                        isPending
                    }
                >
                    Save{" "}
                </SimpleButton>
            </div>
        );
    else return <ErrorCard error={new Error("Please refresh")} />;
};

