import React, { useContext } from "react";
import { baseURL } from "../../constants";
import { useQuery } from "@tanstack/react-query";
import { TimesheetContext } from "../../contexts/TimesheetContext";
import { ParseDate } from "../../utils/dateUtils";
export const fetchRDTimeSheet = async (token: string, begin: Date, end: Date) => {

	const response = await fetch(`${baseURL}/api/RD/Get?beginDate=${ParseDate(begin)}&endDate=${ParseDate(end)}`, {
		method: "GET",
		headers: {
			Accept: "application/json",
			Authorization: `Bearer ${token}`,
		},
	});

	if (!response.ok) {
		throw new Error("Couldn't fetch timesheet data");
	}
	return response.json();
}

export const useGetRDTimesheet = (beginDate: Date, endDate: Date) => {
	const {accessToken} = useContext(TimesheetContext);
	return useQuery({
		queryKey: ["getRDTimesheet", beginDate, endDate],
		queryFn: () => fetchRDTimeSheet(accessToken, beginDate, endDate),
	});
}