import { useContext } from "react";
import { baseURL } from "../../constants";
import { time } from "console";
import { TimesheetContext } from "../../contexts/TimesheetContext";
import { useQuery } from "@tanstack/react-query";
import { EmployeeTypeModel } from "../../models/EmployeeTypeModel";

export const fetchEmployeeTypes = async (token: string) => {
	const response = await fetch(`${baseURL}/api/Employeetype/Get`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});

	if (!response.ok) {
		throw new Error("Failed to fetch employee types");
	}

	return response.json();
};

export const useGetEmployeeTypes = () => {
	const {accessToken} = useContext(TimesheetContext);
	return useQuery<EmployeeTypeModel[], Error>({
		queryKey: ["employeeTypes"],
		queryFn: () => fetchEmployeeTypes(accessToken),
		enabled: !!accessToken,
	})
}